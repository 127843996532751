import * as anchor from "@project-serum/anchor";
import {
    PROGRAM_ID as METADATA_PROGRAM_ID,
    Metadata,
} from "@metaplex-foundation/mpl-token-metadata";
import {
    AccountInfo,
    Connection,
    ParsedAccountData,
    PublicKey,
} from "@solana/web3.js";
import { AnchorWallet } from "@solana/wallet-adapter-react";
import { TOKEN_PROGRAM_ID } from "@solana/spl-token";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import { DEGENERATE_API_ENDPOINT, ENV } from "./constants";
import { APES } from "./mints";
import { sliceIntoChunks } from "./utils";

export interface GetMetadataAddressArgs {
    mint: string;
}

export interface Token {
    account: string;
    legacyMetadata: string | null;
    offChainMetadata: {
        error: string;
        metadata: {
            image: string;
            name: string;
        };
        uri: string;
    };
    onChainAccountInfo: AccountInfo<ParsedAccountData>;
    onChainMetadata: {
        error: string;
        metadata: Metadata;
    };
}

// Global connection for this module.
export const connection: Connection = new Connection(ENV.RPC);

// Function to pull ascend counter.
export const getAscendedCount = async (): Promise<number> => {
    const timeNow = parseInt((new Date().valueOf() / 1000).toString(), 10);
    const response = await fetch(
        `${DEGENERATE_API_ENDPOINT}stats/ascended?timestamp=${timeNow}`
    ).then((r) => r.json());
    console.log(response);
    return response.value;
};

// Function to pull all tokens a particular user owns.
export const getOwnedTokens = async ({ target }: { target: PublicKey }) =>
    connection
        .getParsedTokenAccountsByOwner(target, {
            programId: TOKEN_PROGRAM_ID,
        })
        .then((result) => result.value)
        .then((tokens) =>
            tokens.filter(
                (i) => i.account.data.parsed.info.tokenAmount.uiAmount > 0
            )
        )
        .then((tokens) => tokens.map((i) => i.account.data.parsed.info.mint))
        .then((tokens) => tokens.filter((i) => APES.includes(i)));

// Function to fetch and filter for V1 apes.
// @ts-ignore
export const fetchTokensData = async (
    wallet: AnchorWallet
): Promise<Token[]> => {
    const tokens = await getOwnedTokens({
        target: wallet.publicKey,
    });
    const apiEnv =
        ENV.NETWORK === WalletAdapterNetwork.Mainnet ? "api" : "api-devnet";
    if (tokens.length > 100) {
        const tokenChunks = sliceIntoChunks(tokens, 100);
        let allTokens: Token[] = [];
        for (const chunk of tokenChunks) {
            const response = await fetch(
                `https://${apiEnv}.helius.xyz/v0/token-metadata?api-key=${ENV.HELIUS}`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        mintAccounts: chunk,
                        includeOffChain: true,
                        disableCache: false,
                    }),
                }
            ).then((r) => r.json());
            allTokens = [...allTokens, ...response];
        }
        return allTokens;
    }
    return fetch(
        `https://${apiEnv}.helius.xyz/v0/token-metadata?api-key=${ENV.HELIUS}`,
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                mintAccounts: tokens,
                includeOffChain: true,
                disableCache: false,
            }),
        }
    ).then((r) => r.json());
};

export const getMetadataAddress = ({
    mint,
}: GetMetadataAddressArgs): anchor.web3.PublicKey =>
    anchor.web3.PublicKey.findProgramAddressSync(
        [
            Buffer.from("metadata"),
            METADATA_PROGRAM_ID.toBuffer(),
            new PublicKey(mint).toBuffer(),
        ],
        METADATA_PROGRAM_ID
    )[0];
