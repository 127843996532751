import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import Logo from "./Logo";
import BugReport from "./BugReport";
import Hamburger from "./Hamburger";
import { useState } from "react";
import Menu from "./Menu";

function Header() {
    const [isOpen, setIsOpen] = useState(false);
    const toggleBurger = () => {
        setIsOpen(!isOpen);
    };
    return (
        <>
            <Menu isOpen={isOpen} />
            <div className="absolute w-full h-[100px] flex justify-between items-center top-0 left-0 z-[25]">
                {/* Left Container */}
                <div className="mx-8 my-4">
                    <Logo width={45} height={45} />
                </div>

                {/* Right Container. */}
                <div className="mx-10 my-4 flex flex-row justify-center items-center">
                    <BugReport />
                    <div className="mx-3" />
                    <WalletMultiButton />
                    <div className="mx-3" />
                    <Hamburger onClick={toggleBurger} isOpen={isOpen} />
                </div>
            </div>
        </>
    );
}

export default Header;
