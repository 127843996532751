import { FRAME, Frame } from "./Container";
import AnimationController from "./AnimationController";

// Animation sources.
import FloatingCrystal from "../assets/animations/FLOATING CRYSTAL.webm";
import WalkIn from "../assets/animations/WALK IN.webm";
import Idle from "../assets/animations/IDLE.webm";
import Action from "../assets/animations/ACTION.webm";
import Idle2 from "../assets/animations/IDLE2.webm";

import { useEffect, useState } from "react";
import { sleep } from "../helpers/utils";

function AnimationStack({
    frame,
    setFrame,
}: {
    frame: Frame;
    setFrame: React.Dispatch<React.SetStateAction<Frame>>;
}) {
    const [idleFade, setIdleFade] = useState<number>(250);
    const [idle2Fade, setIdle2Fade] = useState<number>(0);
    useEffect(() => {
        if (frame === `Idle`)
            sleep(2000).then(() => {
                setIdleFade(1000);
                setIdle2Fade(0);
            });
        if (frame === `Reveal`)
            sleep(2000).then(() => {
                setIdleFade(0);
                setIdle2Fade(1000);
            });
    }, [frame]);
    return (
        <>
            <AnimationController
                id="intro"
                active={frame === FRAME.Intro}
                source={FloatingCrystal}
                loop
                zIndex={2}
            />
            <AnimationController
                id="walk-in"
                active={frame === FRAME.WalkIn}
                source={WalkIn}
                onClockTime={3.07}
                onClockEvent={() => setFrame("Idle")}
                zIndex={4}
                fadeDuration={4000}
            />
            <AnimationController
                id="idle"
                active={frame === FRAME.Idle}
                source={Idle}
                loop
                zIndex={5}
                fadeDuration={idleFade}
            />
            <AnimationController
                id="action"
                active={frame === FRAME.Action}
                source={Action}
                zIndex={6}
                fadeDuration={200}
                onClockTime={21.05}
                onClockEvent={() => setFrame("Reveal")}
            />
            <AnimationController
                id="idle2"
                active={frame === FRAME.Reveal}
                source={Idle2}
                loop
                zIndex={5}
                fadeDuration={idle2Fade}
            />
        </>
    );
}

export default AnimationStack;
