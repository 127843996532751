import { useEffect, useState } from "react";

import Confetti from "react-confetti";
import { ContentFrame } from "../ContentStack";
import { Token } from "../../helpers/solana";
import ActionButton from "../ActionButton";
import { Frame } from "../Container";
import { goTo, sleep } from "../../helpers/utils";
import useWindowDimensions from "../../hooks/useWindowDimensions";

function Showcase({
    token,
    getTokens,
    setFrame,
    contentFrame,
    setContentFrame,
}: {
    token: Token | null;
    getTokens: Function;
    setFrame: React.Dispatch<React.SetStateAction<Frame>>;
    contentFrame: ContentFrame;
    setContentFrame: React.Dispatch<React.SetStateAction<ContentFrame>>;
}) {
    const [shown, setShown] = useState(false);
    const { height, width } = useWindowDimensions();
    useEffect(() => {
        if (contentFrame === "Showcase") sleep(10).then(() => setShown(true));
    }, [contentFrame]);
    const confettiColors: string[] = [
        "#c1dadb",
        "#e8acda",
        "#34ebe1",
        "#3434eb",
        "#eb3498",
        "#eb34cf",
        "#34ebb7",
        "#34a4eb",
        "#34e8eb",
        "#7d34eb",
    ];
    return (
        <div className="flex justify-center items-center w-full h-full">
            <Confetti
                height={height}
                width={width}
                numberOfPieces={300}
                colors={confettiColors}
            />
            <div
                className={`relative flex justify-center items-center w-[480px] h-[680px] bg-[#20202099] rounded-3xl shadow-4xl transition duration-[300ms] ${
                    shown && contentFrame === "Showcase"
                        ? "opacity-100"
                        : "opacity-0"
                }`}
            >
                {/* Text */}
                <div className="w-full h-full flex flex-col justify-center items-center">
                    <img
                        className="h-[360px] w-[360px] rounded-2xl"
                        src={token ? token.offChainMetadata.metadata.image : ""}
                        alt="offChainMetadata metadata"
                    />
                    <div className="font-boop mt-[30px] mb-[15px] text-xl">
                        {token ? token.offChainMetadata.metadata.name : ""}
                    </div>
                    <div className="font-montserrat w-[70%] text-center text-sm">
                        Goddamn, you've never looked so pretty! Enjoy earning 4x
                        the Opals you were before!
                    </div>
                    <div className="w-[90%] flex flex-row justify-evenly items-center">
                        <ActionButton
                            text="Do another!"
                            onClick={() => {
                                setShown(false);
                                sleep(10).then(() => setContentFrame("Select"));
                                getTokens();
                                setFrame("Idle");
                            }}
                        />
                        <ActionButton
                            text="Customize it!"
                            onClick={() => {
                                setShown(false);
                                sleep(10).then(() => setContentFrame("Select"));
                                getTokens();
                                setFrame("Idle");
                                goTo(
                                    `https://itsboots.xyz/collections/daa2/nfts/${token?.account}`
                                );
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Showcase;
