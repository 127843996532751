function ActionButton({
    active = true,
    text,
    onClick,
}: {
    active?: boolean;
    text: string;
    onClick?: React.MouseEventHandler<HTMLDivElement>;
}) {
    return (
        <div
            onClick={onClick}
            className={`pt-[2px] mt-[40px] h-[40px] w-[180px] font-boop text-sm flex justify-center items-center bg-[#7b42fb22] border-2 border-[#7b42fb] rounded-xl transition hover:bg-[#7b42fb66] ${
                active
                    ? "cursor-pointer"
                    : "cursor-not-allowed grayscale opacity-80"
            }`}
        >
            {text}
        </div>
    );
}

export default ActionButton;
