import BugIcon from "../../assets/bug.png";

function BugReport() {
    return (
        <div className="w-[42px] h-[38px] bg-[#7b42fb44] border-2 border-[#7b42fb] rounded-lg flex justify-center items-center cursor-pointer transition hover:bg-[#7b42fb99]">
            <img
                style={{ filter: `invert(1)` }}
                src={BugIcon}
                alt=""
                className="w-[18px] h-[18px]"
            />
        </div>
    );
}

export default BugReport;
