import { MouseEventHandler } from "react";

function Hamburger({
    onClick,
    isOpen,
}: {
    onClick: MouseEventHandler<HTMLButtonElement>;
    isOpen: boolean;
}) {
    const styles = {
        hamburgerContainer:
            "w-[1.6rem] h-[1.6rem] flex flex-col flex-nowrap justify-around z-[10] cursor-pointer",
        hamburger:
            "w-[1.6rem] h-[0.2rem] rounded-xl bg-white origin-[1px] transition-all duration-[350ms] ease-lazy ",
        burger1: `${isOpen ? "rotate-45" : "rotate-0"}`,
        burger2: `${
            isOpen ? "translate-x-full opacity-0" : "translate-x-0 opacity-1"
        }`,
        burger3: `${isOpen ? "rotate-[-45deg]" : "rotate-0"}`,
    };
    return (
        <button
            type="button"
            className={styles.hamburgerContainer}
            onClick={onClick}
        >
            <div className={styles.hamburger + styles.burger1} />
            <div className={styles.hamburger + styles.burger2} />
            <div className={styles.hamburger + styles.burger3} />
        </button>
    );
}

export default Hamburger;
