import ProgressiveImage from "react-progressive-graceful-image";
import { Token } from "../../helpers/solana";
import { useState } from "react";
import { getNum } from "../../helpers/utils";

function SelectionGrid({
    data,
    loadingAsset,
    active,
    setActive,
}: {
    data: Token[];
    loadingAsset: string;
    active: Token | null;
    setActive: React.Dispatch<React.SetStateAction<Token | null>>;
}) {
    const [showOverlay, setShowOverlay] = useState<number | null>(null);
    return (
        <div
            id="selection-grid"
            style={{
                gridTemplateColumns:
                    data.length > 0
                        ? `repeat(4, minmax(0, 1fr))`
                        : `repeat(1, minmax(0, 1fr))`,
            }}
            className={`absolute top-[80px] py-[24px] px-[10px] h-[420px] w-[920px] grid overflow-y-auto justify-items-center gap-y-5 bg-[#00000033] rounded-xl inner-shadow`}
        >
            {data.length > 0 &&
                data.map((ape, i) => (
                    <div
                        onMouseEnter={() => setShowOverlay(getNum(ape))}
                        onMouseLeave={() => setShowOverlay(null)}
                        onClick={() => {
                            if (active && getNum(ape) === getNum(active)) {
                                setActive(null);
                            } else {
                                setActive(ape);
                            }
                        }}
                        className=""
                        key={i}
                    >
                        <ProgressiveImage
                            src={ape.offChainMetadata.metadata.image}
                            placeholder={loadingAsset}
                        >
                            {(src, loading) => (
                                <div className="relative flex justify-center items-center">
                                    <img
                                        className={`relative w-[200px] h-[200px] rounded-2xl cursor-pointer transition duration-[500ms] border-4 border-[#00000000] ${
                                            active &&
                                            getNum(ape) === getNum(active) &&
                                            `!border-[#7b42fb]`
                                        } `}
                                        style={{ opacity: loading ? 0.5 : 1 }}
                                        src={src}
                                        alt={
                                            ape.offChainMetadata.metadata.image
                                        }
                                    />
                                    <div
                                        className={`absolute top-[3px] flex justify-center items-center w-[193px] h-[194px] font-boop text-2xl rounded-[12px] pointer-events-none transition duration-[300ms] bg-[#00000066] backdrop-blur-sm ${
                                            getNum(ape) === showOverlay
                                                ? `opacity-100`
                                                : `opacity-0`
                                        }`}
                                    >
                                        {`#${getNum(ape)}`}
                                    </div>
                                </div>
                            )}
                        </ProgressiveImage>
                    </div>
                ))}
            {data.length === 0 && (
                <div className="w-full h-full font-montserrat flex justify-center items-center opacity-60 text-sm italic">
                    You have no apes to select!
                </div>
            )}
        </div>
    );
}

export default SelectionGrid;
