import { useEffect, useState } from "react";
import { sleep } from "../helpers/utils";
import useInterval from "../hooks/useInterval";

function AnimationController({
    id,
    active,
    source,
    loop = false,
    zIndex = 0,
    fadeDuration = 500,
    onClockTime,
    onClockEvent,
    onEnded,
}: {
    id: string;
    active: boolean;
    source: string;
    loop?: boolean;
    zIndex?: number;
    fadeDuration?: number;
    onClockTime?: number;
    onClockEvent?: Function;
    onEnded?: React.AnimationEventHandler<HTMLVideoElement>;
}) {
    // To prevent the animation from just appear/disappearing, we have to make sure
    // the element exists before applying the opacity transition. So we set a
    // small timeout to allow the animation to appear before transitioning.
    const [hidden, setHidden] = useState<boolean>(true);
    const [clockTriggered, setClockTriggered] = useState<boolean>(false);
    useEffect(() => {
        if (active) {
            // Reset the animation.
            const element = document.getElementById(id) as HTMLVideoElement;
            element.currentTime = 0;
            element.play();
            setClockTriggered(false);

            // Trigger display.
            sleep(10).then(() => setHidden(false));
        }
    }, [active]);

    // This controls the time-bound function onClockEvent, and checks for animation time
    // to breach the onClockTime threshold every 10ms, so as to activate onClockEvent.
    useInterval(() => {
        if (onClockTime && onClockEvent && !clockTriggered) {
            const element = document.getElementById(id) as HTMLVideoElement;
            if (element && element.currentTime >= onClockTime) {
                onClockEvent();
                setClockTriggered(true);
            }
        }
    }, 10);

    // Return this boi.
    return (
        (active || !hidden) && (
            <video
                id={id}
                loop={loop}
                muted
                autoPlay
                src={source}
                className={`absolute object-cover min-h-full min-w-full transition ease-in-out ${
                    active && !hidden
                        ? "opacity-100 pointer-events-auto"
                        : "opacity-0 pointer-events-none"
                }`}
                style={{
                    zIndex: `${zIndex}`,
                    transitionDuration: `${fadeDuration}ms`,
                }}
                preload="auto"
                onEnded={onEnded}
            />
        )
    );
}

export default AnimationController;
