export default function Logo({
    width = 40,
    height = 40,
}: {
    width: number;
    height: number;
}) {
    return (
        <svg
            width={width}
            height={height}
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 550 630"
        >
            <g>
                <path
                    className="st0"
                    d="M165.9,237.5c10-0.2,17.9-7.1,17.7-15.3c-0.1-2.6-0.1-4.5-0.7-5.8c-16.1,0.1-28.8,1.2-35,1.8
		c-0.3,1.2-0.4,2.8-0.3,4.8C147.8,231.2,156,237.7,165.9,237.5z"
                />
                <path
                    className="st0"
                    d="M324.1,279l-2.7-0.9c-12.5-4.1-11.6-12.5-1.3-13.5c0.9-0.1,7-0.7,7.9-0.8c6.6-0.8,11.7-4,13.5-11.5
		c2.3-9.2,0.6-17.7-5.9-24.8c-12.3-13.4-31.2-14.1-47.1-1.9c-2,1.5-4,3-6.1,4.4c-7.9,5.1-14.7,0-14.7,0c-2.1-1.4-4.1-2.9-6.1-4.4
		c-15.9-12.1-34.7-11.4-47.1,1.9c-6.5,7.1-8.2,15.6-5.9,24.8c1.9,7.5,6.9,10.7,13.5,11.5c0.9,0.1,7,0.7,7.9,0.8
		c10.3,1,12.6,8.6-0.7,13.4l-3.3,1.2c-55.8,19.4-111.4,58.5-112.5,130.7c0,0-10.5,145.4,160.7,148.9h1
		c171.7-3.2,161.2-148.9,161.2-148.9C435.6,337.7,380,298.3,324.1,279z M292.9,245.7c3.7-4.9,10.3-8.3,16-9.9
		c5.6-1.6,13.9-1.5,15.2,7.1c0.3,2.7-3.1,4.7-3.9,4.9c-8.7,2.6-14.4,3.6-24.3,4.7C292.4,252.9,288.3,251.8,292.9,245.7z
		 M230.1,247.8c-0.8-0.2-4.2-2.1-3.9-4.9c1.3-8.6,9.6-8.7,15.2-7.1c5.7,1.6,12.3,5,16,9.9c4.6,6.1,0.5,7.2-3.1,6.8
		C244.5,251.4,238.8,250.4,230.1,247.8z M319.9,458.4c-3.5,1.5-7,2.6-10.5,3.4c-3.5,0.8-7,1.3-10.5,1.8s-7,0.7-10.5,0.9
		c-3.5,0.1-7,0.1-10.5-0.2c-3.5,0-16.2-0.5-19.7-0.9s-6.9-0.9-10.3-1.6c-3.4-0.7-6.8-1.5-10.2-2.5c-3.4-1-6.7-2.3-9.8-4.1
		c-0.8-0.4-1.3-1.6-1.1-2.9c0.2-1.6,1.3-2.7,2.3-2.6l0,0c6.7,0.9,13.3,2.6,20,3.4c3.4,0.4,6.7,0.8,10.1,1c1.8,0.1,7.2,0.4,12.3,0.6
		c3.6,0.1,10.7,0.3,19.4,0.5c2.7,0,5.5-0.2,8.2-0.3c6.8-0.4,13.6-1.5,20.5-2l0,0c1.1-0.1,1.9,1.1,1.9,2.7
		C321.4,456.9,320.7,458.1,319.9,458.4z M377.8,423.1c-0.4,3.3-2.6,5.6-4.9,5.3l0,0c-14.4-2.1-28.5-5.7-42.8-7.6
		c-7.2-0.9-14.3-1.8-21.5-2.4c-3.9-0.3-15.4-0.9-26.3-1.5c-7.6-0.3-22.8-1-41.1-1.4c-5.8,0-11.7,0.2-17.5,0.4
		c-14.5,0.6-29,2.8-43.5,3.6l0,0c-2.3,0.1-4.2-2.4-4.2-5.7c0-2.7,1.4-5.1,3.2-5.9c7.3-3.1,14.7-5.2,22.2-6.7
		c7.4-1.5,14.9-2.5,22.4-3.4s14.9-1.3,22.4-1.5s14.9,0.1,22.4,0.6c7.5,0.2,34.6,1.5,42,2.4s14.8,2,22.1,3.6s14.6,3.3,21.8,5.5
		s14.3,4.9,21.2,8.7C377.1,417.9,378.2,420.4,377.8,423.1z"
                />
                <path
                    className="st0"
                    d="M278.6,454.9C278.7,454.9,278.7,454.9,278.6,454.9l-4.9-0.2C275.6,454.8,277.2,454.9,278.6,454.9z"
                />
                <path
                    className="st0"
                    d="M65.3,257.9c-1.6-0.1-3,1.2-3.1,2.8v0.4c-0.2,3.3-0.4,6.9-0.8,10.1c-0.5,3.3-1.1,6.6-2.1,9.6
		c-0.9,3.1-2.3,6-3.9,8.8c-1.5,2.9-3.7,5.3-5.8,8.1l-0.2,0.3c-0.7,0.9-0.8,2.2-0.3,3.2c0.8,1.4,2.6,2,4,1.2c3.5-1.9,6.9-4.5,9.5-7.8
		c2.6-3.2,4.6-7,5.8-11c1.2-4,1.8-8,1.7-12.1c-0.1-4.1-0.7-7.9-2.3-11.9C67.5,258.8,66.5,258,65.3,257.9z"
                />
                <path
                    className="st0"
                    d="M267.1,416.2l10.4,0.5c-3.8-0.2-7.4-0.4-10.3-0.5C267.2,416.2,267.1,416.2,267.1,416.2z"
                />
                <path
                    className="st0"
                    d="M534.7,284c-8.6-7-13.6-19.3-13.4-33c0.5-25.5-1.7-49.8-21-54.4c-9.5-2.2-17.7-0.5-23.8,4.8
		c-10.2-25.4-22-51.2-35.2-77c-30.2-59-78.2-82.2-113.6-91.2c-8.7-4.8-13.7-10.7-18.2-15.8c-4.9-5.7-10-11.5-18.2-12.6
		c-3.8-0.5-9.4,0-14.5,5.3c-5.2-5.3-10.9-5.8-14.7-5.3c-8.3,1-13.2,6.6-18.5,12.5c-4.9,5.5-10.5,11.7-20.5,16.7
		c-34.3,9.4-81.1,32.7-110.7,90.5c-13.7,26.7-25.3,51.6-35.3,75.7c-6-4.2-13.5-5.4-22-3.4c-19.2,4.6-21.4,28.9-21,54.4
		c0.3,13.7-4.7,26-13.3,32.9C6.5,295.5-9,319.6,6.5,339c3.8,4.7,8.6,7.9,14.2,9.5c-7,24.9-13.9,62.6-3.7,92.2
		c1.4,4,5.5,16.1,15.6,17.5c1.4,0.2,2.7,0.2,4,0c0.2,0.8,0.4,1.7,0.5,2.5c4.7,31.2,15.1,74.3,38.6,102.9
		c8.6,10.5,16.6,15.1,24.4,14.1c2.2-0.3,4.7-1.1,7.2-3c39.9,30.9,94.2,47.5,161.2,49.2c0.6,0,3.4,0.1,7.9,0.1
		c25.6,0,107.4-3.9,170.1-50.7c2.8,2.6,5.7,3.8,8.2,4.2c7.9,1.4,16.1-3.2,25-14c24.1-29.4,34.5-74.1,39-105.4
		c1.3,0.2,2.6,0.3,3.9,0.1c10.3-1.3,14.5-13.5,15.9-17.6c10.4-30.2,2.6-69.5-3.8-92.2c5.6-1.6,10.4-4.8,14.2-9.5
		C564.5,319.6,548.9,295.5,534.7,284z M542.2,333.5c-5.2,6.5-12.3,7.9-18.8,7.3c6.1,18.8,18.2,64.2,6.9,97.1
		c-5.6,16.3-12.3,13.7-18.4,4.3c-3.1,29.7-12.1,83.3-38.9,115.9c-12.7,15.5-20.5,13.1-24.3,2.5C376.4,620.5,269,615.2,269,615.2
		c-78.4-2.1-129.5-24.2-163-52.8c-4,9-11.6,10.3-23.6-4.3c-22.3-27.1-32.3-68.7-36.8-99c-1.2-5.3-2.2-10.3-2.9-14.7
		c-0.1-0.3-0.1-0.6-0.1-1c-5.8,8.3-12.2,10-17.5-5.6c-11.3-32.9,0.9-78.3,6.9-97.1c-6.5,0.6-13.6-0.8-18.8-7.3
		c-12.7-15.8,4.5-36,12.9-42.7c10.5-8.4,16.9-23,16.6-39.8c-0.5-24.4,1.9-42.8,14.3-45.8c6.7-1.6,16.9-1.6,22.4,12.1
		c13.7-34.6,28.5-65.3,40.6-88.9c27.9-54.5,71.6-77.1,106-86.3c22.4-10.9,26.2-27.4,37-28.7c7.7-1,11.8,8.3,13.8,15.7
		c1.9-7.4,5.8-16.6,13.3-15.7c10.2,1.3,14,17.1,34.3,27.9c34.8,8.7,80.3,31,109,87.1c12.5,24.4,27.5,56.3,41.2,92.4
		c5.1-17.3,16.4-17.4,23.6-15.6c12.4,3,14.8,21.3,14.3,45.8c-0.3,16.8,6.1,31.4,16.6,39.8C537.7,297.5,554.9,317.7,542.2,333.5z"
                />
                <path
                    className="st0"
                    d="M413.6,223c0-2,0-3.6-0.3-4.8c-6.2-0.6-19-1.7-35-1.8c-0.6,1.3-0.7,3.2-0.7,5.8c-0.2,8.2,7.8,15,17.7,15.3
		C405.2,237.7,413.4,231.2,413.6,223z"
                />
                <path
                    className="st0"
                    d="M505.9,297.8c-2.1-2.8-4.3-5.2-5.8-8.1c-1.6-2.8-3-5.7-3.9-8.8c-1-3.1-1.6-6.3-2.1-9.6
		c-0.4-3.3-0.7-6.9-0.8-10.1v-0.4c-0.1-1.1-0.8-2.1-1.9-2.6c-1.5-0.6-3.2,0.1-3.8,1.6c-1.6,4-2.2,7.8-2.3,11.9
		c-0.1,4,0.5,8.1,1.7,12.1c1.2,3.9,3.2,7.7,5.8,11c2.5,3.3,5.9,5.9,9.5,7.8c1,0.5,2.3,0.5,3.2-0.3c1.3-1,1.5-2.9,0.5-4.2
		L505.9,297.8z"
                />
                <path
                    className="st0"
                    d="M433.9,197.6c-11.5-3.4-110.5-15-154.8-15c-56.7,0-151.2,11.6-162.7,15c-2.8,0.9-4.8,3.3-4.6,6
		c0.3,3.2,3.5,5.7,7.3,5.5l0,0c23.4-1.4,128.8-6.9,164.6-7.2c35.8-0.3,123.9,5.7,147.3,7.2l0,0c3.8,0.2,7-2.2,7.3-5.5
		C438.7,200.9,436.8,198.4,433.9,197.6z"
                />
            </g>
        </svg>
    );
}
