import { useEffect, useState } from "react";
import { useWallet } from "@solana/wallet-adapter-react";
import Header from "./Header/Header";
import ContentStack from "./ContentStack";
import AnimationStack from "./AnimationStack";
import Footer from "./Footer";
import AscendCounter from "./AscendCounter";

export const FRAME = {
    Loader: "Loader",
    Intro: "Intro",
    WalkIn: "WalkIn",
    Idle: "Idle",
    Selection: "Selection",
    Action: "Action",
    Reveal: "Reveal",
    Error: "Error",
    End: "End",
} as const;

export type Frame = keyof typeof FRAME;

function Container() {
    const { publicKey } = useWallet();

    const [frame, setFrame] = useState<Frame>(FRAME.Intro);
    const [showContent, setShowContent] = useState<boolean>(false);

    useEffect(() => {
        if (publicKey) {
            setFrame(FRAME.WalkIn);
        }
    }, [publicKey]);

    useEffect(() => {
        if (frame === FRAME.Idle) setTimeout(() => setShowContent(true), 2000);
        if (frame === FRAME.Action)
            setTimeout(() => setShowContent(false), 1000);
        if (frame === FRAME.Reveal) setTimeout(() => setShowContent(true), 100);
    }, [frame]);

    return (
        <>
            <Header />
            <div className="w-screen h-screen bg-[#000] overflow-y-hidden">
                <AnimationStack frame={frame} setFrame={setFrame} />
                <ContentStack active={showContent} setFrame={setFrame} />
            </div>
            <Footer />
            <AscendCounter />
        </>
    );
}

export default Container;
