import { faDiscord, faTwitter } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Menu = ({ isOpen }: { isOpen: boolean }) => {
    // Menu Items
    const items = [
        { name: "enroll", link: "https://www.degenape.academy/#apeintosh" },
        { name: "team", link: "https://www.degenape.academy/#team" },
        { name: "history", link: "https://history.degenape.academy/" },
        { name: "mining", link: "https://www.degenape.academy/mining/" },
        {
            name: "hatchening",
            link: "https://www.degenape.academy/hatchening/",
        },
        { name: "explorer", link: "https://www.degenape.academy/explorer/" },
    ];

    // Links
    const links = {
        privacyPolicy: `https://www.degenape.academy/privacy/DAA_Club_Terms_of_Service.pdf`,
        twitter: `https://twitter.com/DegenApeAcademy`,
        discord: `https://discord.gg/degenapeacademy`,
    };

    // Styles
    const styles = {
        container: `absolute top-0 left-0 w-screen h-screen flex flex-col justify-center 
            items-center z-[25] bg-menu transition-opacity duration-[300ms] 
            ${
                isOpen
                    ? "opacity-100 pointer-events-auto"
                    : "opacity-0 pointer-events-none"
            }`,
        menuSelectionContainer: `flex flex-col text-center`,
        menuItem: `text-4xl font-[400] uppercase font-boop my-6 
            text-[#ffffff80] transition-all duration-[150ms] ease-lazy 
            hover:text-[#FBE271] hover:scale-150`,
        footer: `absolute bottom-[80px] w-screen flex flex-col justify-center items-center`,
        social: `flex flex-row mb-20`,
        socialIcon: `transition-all duration-[150ms] ease-lazy cursor-pointer
            hover:text-[#FBE271] hover:scale-150`,
        privacyPolicy: `font-montserrat text-lg font-[500] transition-all 
            duration-[150ms] ease-lazy hover:text-[#FBE271] hover:scale-[1.2]`,
    };

    return (
        <div className={styles.container}>
            <div className={styles.menuSelectionContainer}>
                {items.map((item) => (
                    <div key={item.name} className={styles.menuItem}>
                        <a href={item.link}>{item.name}</a>
                    </div>
                ))}
                <div className="my-20"></div>
            </div>
            <div className={styles.footer}>
                <div className={styles.social}>
                    <a href={links.twitter}>
                        <FontAwesomeIcon
                            className={styles.socialIcon}
                            icon={faTwitter}
                            size="4x"
                        />
                    </a>
                    <div className="mx-4"></div>
                    <a href={links.discord}>
                        <FontAwesomeIcon
                            className={styles.socialIcon}
                            icon={faDiscord}
                            size="4x"
                        />
                    </a>
                </div>
                <div className={styles.privacyPolicy}>
                    <a href={links.privacyPolicy}>
                        Privacy Policy & Terms of Service
                    </a>
                </div>
            </div>
        </div>
    );
};

export default Menu;
