import { useEffect, useState } from "react";
import { Token } from "../../helpers/solana";
import { getNum, sleep } from "../../helpers/utils";
import ActionButton from "../ActionButton";
import { ContentFrame } from "../ContentStack";
import { JellyTriangle } from "@uiball/loaders";
import Alert from "../Icon/Alert";

function Confirmation({
    action,
    active,
    contentFrame,
    setContentFrame,
    txState,
    setTxState,
    isError,
    setIsError,
}: {
    action: Function;
    active: Token | null;
    contentFrame: ContentFrame;
    setContentFrame: React.Dispatch<React.SetStateAction<ContentFrame>>;
    txState: string[];
    setTxState: React.Dispatch<React.SetStateAction<string[]>>;
    isError: boolean;
    setIsError: React.Dispatch<React.SetStateAction<boolean>>;
}) {
    const [shown, setShown] = useState(false);
    const [isBurning, setIsBurning] = useState(false);

    // Revoke the progress window on error.
    useEffect(() => {
        if (isError) setIsBurning(false);
    }, [isError]);

    useEffect(() => {
        if (contentFrame === `Confirm`) sleep(10).then(() => setShown(true));
    }, [contentFrame]);
    return (
        <div className="flex justify-center items-center w-full h-full">
            <div
                className={`relative flex justify-center items-center w-[1000px] h-[420px] bg-[#20202099] rounded-3xl shadow-4xl transition duration-[300ms] ${
                    shown && contentFrame === `Confirm`
                        ? `opacity-100`
                        : `opacity-0`
                }`}
            >
                {/* When error. */}
                {isError && (
                    <div className="w-full h-full flex flex-col justify-center items-center">
                        <Alert width={50} height={50} />
                        <div className="font-boop mb-[10px] text-xl mt-8">
                            {txState[0]}
                        </div>
                        <div className="font-montserrat mt-[6px] text-sm w-[70%] text-center">
                            {txState[0].includes(`Your Ape is mining.`) ? (
                                <div>
                                    Looks like this ape is currently working
                                    hard in the mines. Head over to{" "}
                                    <a
                                        className="underline"
                                        href="https://degenape.academy/mining/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        https://degenape.academy/mining/
                                    </a>{" "}
                                    to relieve him of his duties, then try
                                    again.
                                </div>
                            ) : (
                                txState[1]
                            )}
                        </div>
                        <ActionButton
                            text="Fuck, not ok."
                            onClick={() => {
                                setShown(false);
                                sleep(250).then(() => {
                                    setIsError(false);
                                    setContentFrame(`Select`);
                                });
                            }}
                        />
                    </div>
                )}

                {/* When ascending and no error. */}
                {!isError && isBurning && (
                    <div className="w-full h-full flex flex-col justify-center items-center">
                        <JellyTriangle color="#FFFFFF99" />
                        <div className="absolute bottom-[100px] font-montserrat mt-[30px] text-xs">
                            {txState[0]}
                        </div>
                    </div>
                )}

                {/* When confirming and no error. */}
                {!isError && !isBurning && (
                    <>
                        <div
                            onClick={() => {
                                setShown(false);
                                sleep(250).then(() =>
                                    setContentFrame(`Select`)
                                );
                            }}
                            className="absolute top-10 left-14 font-montserrat text-xs text-[#ffffff99] cursor-pointer transition hover:text-white"
                        >
                            ⬅&nbsp;&nbsp;&nbsp;Go Back
                        </div>
                        <div className="w-full h-full flex flex-col justify-center items-center">
                            <div className="font-boop mb-[18px] text-lg">
                                For The Apes In The Back
                            </div>
                            <div className="font-montserrat text-sm px-[120px] text-center">
                                This process is irreversible. No amount of
                                crying to Mommy will bring back your immutable #
                                {active && getNum(active)} V1 ape once you
                                proceed. Thankfully, you're not a pussy and
                                you're about to have a lot of fun.
                            </div>
                            <ActionButton
                                text="Fuck, ok."
                                onClick={() => {
                                    setTxState([
                                        `Initializing ascension program...`,
                                    ]);
                                    setIsBurning(true);
                                    sleep(250).then(() => action());
                                }}
                            />
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}

export default Confirmation;
