import { useState } from "react";

import { useAnchorWallet } from "@solana/wallet-adapter-react";
import { PublicKey } from "@solana/web3.js";
import * as anchor from "@project-serum/anchor";
import { Http } from "@raindrops-protocol/raindrops";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import { Token, fetchTokensData } from "../helpers/solana";
import { FRAME, Frame } from "./Container";
import Intro from "./Content/Intro";
import Selection from "./Content/Selection";
import Confirmation from "./Content/Confirmation";
import Showcase from "./Content/Showcase";
import { getNum, sleep } from "../helpers/utils";

import { BOOTS_API_KEY, DAA_V2_ITEM_CLASS, ENV } from "../helpers/constants";
import { getEnrollmentAccounts } from "../helpers/api";

export const CONTENT_FRAME = {
    Intro: "Intro",
    Select: "Select",
    Confirm: "Confirm",
    Loader: "Loader",
    Showcase: "Showcase",
} as const;

export type ContentFrame = keyof typeof CONTENT_FRAME;

function ContentStack({
    active,
    setFrame,
}: {
    active: boolean;
    setFrame: React.Dispatch<React.SetStateAction<Frame>>;
}) {
    const wallet = useAnchorWallet();

    const [txState, setTxState] = useState<string[]>([
        "Initializing token state...",
    ]);
    const [contentFrame, setContentFrame] = useState<ContentFrame>("Intro");
    const [v1Ape, setV1Ape] = useState<Token | null>(null);
    const [v2Ape, setV2Ape] = useState<Token | null>(null);
    const [showApeLoader, setShowApeLoader] = useState<boolean>(true);
    const [allApes, setAllApes] = useState<Token[]>([]);
    const [isError, setIsError] = useState<boolean>(false);

    const getTokens = async () => {
        if (wallet) {
            setShowApeLoader(true);
            try {
                const tokens = await fetchTokensData(wallet);
                tokens.sort((a, b) => getNum(a) - getNum(b));
                setAllApes(tokens);
                setShowApeLoader(false);
            } catch (e) {
                console.log(e);
            }
        }
    };

    // Function to ascend/burn ape.
    const burnApe = async () => {
        if (wallet && v1Ape) {
            try {
                const mint = new PublicKey(v1Ape.account);
                setTxState(["Fetching token status..."]);
                const enrollmentAccounts = await getEnrollmentAccounts({
                    connection: new anchor.web3.Connection(ENV.RPC),
                    wallet,
                    tokens: [mint.toBase58()],
                });
                if (enrollmentAccounts.length > 0) {
                    setIsError(true);
                    setTxState(["Your Ape is mining."]);
                    return;
                }
                setTxState(["Creating new ascension client..."]);
                const bootsClient = new Http.Item.Client(
                    new anchor.AnchorProvider(
                        new anchor.web3.Connection(ENV.RPC),
                        wallet,
                        {
                            preflightCommitment: "processed",
                            commitment: "confirmed",
                        }
                    ),
                    ENV.STRING as anchor.web3.Cluster,
                    ENV.RPC,
                    BOOTS_API_KEY
                );

                setTxState([
                    "Fetching deterministic ingredients from client...",
                ]);
                const result =
                    await bootsClient.getDeterministicIngredientOutput(
                        mint,
                        new PublicKey(DAA_V2_ITEM_CLASS)
                    );
                if (!result) {
                    throw new Error(
                        "Failed to fetch deterministic ingredient output."
                    );
                }
                const v2 = result[0];

                const apiEnv =
                    ENV.NETWORK === WalletAdapterNetwork.Mainnet
                        ? "api"
                        : "api-devnet";

                setTxState(["Fetching token data from network..."]);
                const tokenData: Token[] = await fetch(
                    `https://${apiEnv}.helius.xyz/v0/token-metadata?api-key=${ENV.HELIUS}`,
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                            mintAccounts: [v2.mint],
                            includeOffChain: true,
                            disableCache: true,
                        }),
                    }
                ).then((r) => r.json());

                setTxState(["Invoking assension..."]);
                await bootsClient.build(new PublicKey(DAA_V2_ITEM_CLASS), [
                    { itemMint: mint, amount: new anchor.BN(1) },
                ]);

                setTxState(["Assension completed successfully."]);
                setV2Ape(tokenData[0]);
                setFrame(FRAME.Action);

                // Change to Showcase content frame.
                sleep(3000).then(() => {
                    setContentFrame("Showcase");
                    setShowApeLoader(true);
                });
            } catch (e) {
                setIsError(true);
                setTxState([
                    "Something went wrong.",
                    "Damn. Something went wrong and someone is getting fired. Try again and if the issue persists, reach out to the team for help.",
                ]);
                console.log(e);
            }
        }
    };

    return (
        <div
            className={`z-[10] w-full h-full absolute top-0 left-0 backdrop-blur-md bg-[#00000088] transition duration-[500ms] ease-in-out ${
                active === true
                    ? "opacity-100 pointer-events-auto"
                    : "opacity-0 pointer-events-none"
            }`}
        >
            {/* Intro Section */}
            {contentFrame === "Intro" && (
                <Intro
                    action={getTokens}
                    contentFrame={contentFrame}
                    setContentFrame={setContentFrame}
                />
            )}

            {/* Ape Selection Section */}
            {contentFrame === "Select" && (
                <Selection
                    data={allApes}
                    active={v1Ape}
                    setActive={setV1Ape}
                    showApeLoader={showApeLoader}
                    contentFrame={contentFrame}
                    setContentFrame={setContentFrame}
                />
            )}

            {/* Burn Confirmation Section */}
            {contentFrame === "Confirm" && (
                <Confirmation
                    action={burnApe}
                    active={v1Ape}
                    contentFrame={contentFrame}
                    setContentFrame={setContentFrame}
                    txState={txState}
                    setTxState={setTxState}
                    isError={isError}
                    setIsError={setIsError}
                />
            )}

            {/* Ape Showcase Section */}
            {contentFrame === "Showcase" && (
                <Showcase
                    token={v2Ape}
                    getTokens={getTokens}
                    setFrame={setFrame}
                    contentFrame={contentFrame}
                    setContentFrame={setContentFrame}
                />
            )}
        </div>
    );
}

export default ContentStack;
