import ActionButton from "../ActionButton";
import DemoApe from "../../assets/demo_ape.png";
import { ContentFrame } from "../ContentStack";
import { useEffect, useState } from "react";
import { sleep } from "../../helpers/utils";

function Intro({
    action,
    contentFrame,
    setContentFrame,
}: {
    action: Function;
    contentFrame: ContentFrame;
    setContentFrame: React.Dispatch<React.SetStateAction<ContentFrame>>;
}) {
    const [shown, setShown] = useState(false);
    useEffect(() => {
        if (contentFrame === `Intro`) sleep(10).then(() => setShown(true));
    }, [contentFrame]);
    return (
        <div className="flex justify-center items-center w-full h-full">
            <div
                className={`flex justify-center items-center w-[960px] h-[400px] bg-[#20202099] rounded-3xl shadow-4xl transition duration-[300ms] ${
                    shown && contentFrame === `Intro`
                        ? `opacity-100`
                        : `opacity-0`
                }`}
            >
                {/* Image + Opal */}
                <div className="w-[40%] h-full flex justify-center items-center">
                    <img
                        src={DemoApe}
                        className="ml-[60px] h-[300px] w-[300px] rounded-xl"
                    />
                </div>

                {/* Text */}
                <div className="pl-[55px] w-[70%] h-full flex flex-col justify-center items-left">
                    <div className="font-boop mb-[18px] text-xl">
                        What is Higher Self?
                    </div>
                    <div className="pr-[80px] font-montserrat text-sm">
                        Higher Self is the next stage of the Degenerate Ape
                        Academy. It's a system of customization and
                        personalization. It's how we'll be releasing new traits,
                        directly from Monoliff, over the years ahead.
                        <br />
                        <br />
                        In order to participate you need to exchange your
                        immutable and already beautiful ape for a mutable and
                        even more beautiful version. Everyone loves shiny shit.
                    </div>
                    <ActionButton
                        text="LFG"
                        onClick={() => {
                            setShown(false);
                            sleep(250).then(() => setContentFrame(`Select`));
                            action();
                        }}
                    />
                </div>
            </div>
        </div>
    );
}

export default Intro;
