function Footer() {
    return (
        <div className="absolute font-montserrat w-full bottom-0 left-0 h-[120px] z-[99] text-xs flex justify-center items-center text-[#ffffff] pointer-events-none">
            Made with{" "}
            <a href="https://degenape.academy/" className="text-[#7b42fb]">
                &nbsp;♥&nbsp;
            </a>{" "}
            by
            <b>&nbsp;Degenerate Ape Academy</b>
        </div>
    );
}

export default Footer;
