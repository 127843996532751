import { Connection, Keypair, PublicKey, SystemProgram } from "@solana/web3.js";
import {
    getAssociatedTokenAddressSync,
    TOKEN_PROGRAM_ID,
} from "@solana/spl-token";
import * as anchor from "@project-serum/anchor";
import type { AnchorWallet } from "@solana/wallet-adapter-react";
import { connection } from "./solana";
import { GOLD_STAR_ID, OPAL_ID } from "../helpers/constants";
import { PROGRAM_ID as METADATA_PROGRAM_ID } from "@metaplex-foundation/mpl-token-metadata";

/*
    Constants
*/

export const STAR_SEED: string = `DEGENERATESTAR`;
export const METADATA_SEED: string = `DEGENERATESTARMETADATA`;
export const BOOK_SEED: string = `DEGENERATESTICKERBOOK`;
export const VOUCHER_SEED: string = `DEGENERATEVOUCHER`;
export const ENROLLMENT_SEED: string = `DEGENERATEDRIP`;

export const PROGRAM_ID: PublicKey = new PublicKey(
    `StarLA9YsjQc6P21ALscSkXKE7mqUGsjfhCGBwmJPfS`
);
export const TOKEN_AUTH_RULES_PROGRAM_ID: PublicKey = new PublicKey(
    `auth9SigNpDKz4sJJ1DfCTuZrZNSAgh9sFD3rboVmgg`
);

/*
    Types
*/

export interface GetProgramArgs {
    connection: Connection;
    wallet: AnchorWallet;
}

export interface GetStarAddressArgs {
    creator: PublicKey;
}

export interface GetMetadataAddressArgs {
    token: PublicKey;
    star: PublicKey;
}

export interface GetBookAddressArgs {
    owner: PublicKey;
    star: PublicKey;
}

export interface GetVoucherAddressArgs {
    token: PublicKey;
    star: PublicKey;
}

export interface GetEnrollmentAddressArgs {
    owner: PublicKey;
    star: PublicKey;
    token: PublicKey;
}

export interface GetTokenMetadataAddressArgs {
    mint: anchor.web3.PublicKey;
}

export interface GetEditionAddressArgs {
    mint: anchor.web3.PublicKey;
}

export interface GetTokenRecordAddressArgs {
    owner: PublicKey;
    token: PublicKey;
}

export interface CreateBookArgs {
    connection: Connection;
    wallet: AnchorWallet;
}

export interface CreatePouchArgs {
    connection: Connection;
    wallet: AnchorWallet;
}

export interface ClaimRewardArgs {
    connection: Connection;
    wallet: AnchorWallet;
    token: PublicKey;
    tokenAccount: PublicKey;
}

export interface BurnStarsArgs {
    connection: Connection;
    wallet: AnchorWallet;
    amount: number;
}

export interface RedeemVoucherArgs {
    connection: Connection;
    wallet: AnchorWallet;
    token: PublicKey;
    tokenAccount: PublicKey;
}

export interface EnrollTokenArgs {
    connection: Connection;
    wallet: AnchorWallet;
    token: PublicKey;
    tokenAccount: PublicKey;
    tokenAuthRules?: PublicKey;
}

export interface UnenrollTokenArgs {
    connection: Connection;
    wallet: AnchorWallet;
    token: PublicKey;
    tokenAccount: PublicKey;
    tokenAuthRules?: PublicKey;
}

export interface ClaimAttendanceRewardArgs {
    connection: Connection;
    wallet: AnchorWallet;
    token: PublicKey;
    tokenAccount: PublicKey;
}

export interface GetMetadataAccountsArgs {
    connection: Connection;
    wallet: AnchorWallet;
    tokens: string[];
}

export interface GetVoucherAccountsArgs {
    connection: Connection;
    wallet: AnchorWallet;
    tokens: string[];
}

export interface GetBookAccountArgs {
    connection: Connection;
    wallet: AnchorWallet;
}

export interface GetEnrollmentAccountsArgs {
    connection: Connection;
    wallet: AnchorWallet;
    tokens: string[];
}

export type StarAccountInfo = {
    authority: PublicKey;
    creator: PublicKey;
    bump: number;
    kind: string;
    name: string;
    image: string;
};

export type MetadataAccountInfo = {
    authority: PublicKey;
    token: PublicKey;
    star: PublicKey;
    mint: PublicKey;
    rewardsRemaining: number;
    starsPerRedemption: number;
};

export type BookAccountInfo = {
    owner: PublicKey;
    star: PublicKey;
    amount: anchor.BN;
};

export type VoucherAccountInfo = {
    token: PublicKey;
    star: PublicKey;
    redeemer: PublicKey;
    amount: anchor.BN;
    used: boolean;
};

export type EnrollmentAccountInfo = {
    owner: PublicKey;
    mint: PublicKey;
    star: PublicKey;
    book: PublicKey;
    collection: PublicKey;
    dripTime: anchor.BN;
};

export interface StarAccount extends Object {
    account: StarAccountInfo;
    publicKey: PublicKey;
}

export interface MetadataAccount extends Object {
    account: MetadataAccountInfo;
    publicKey: PublicKey;
}

export interface BookAccount extends Object {
    account: BookAccountInfo;
    publicKey: PublicKey;
}

export interface VoucherAccount extends Object {
    account: VoucherAccountInfo;
    publicKey: PublicKey;
}

export interface EnrollmentAccount extends Object {
    account: EnrollmentAccountInfo;
    publicKey: PublicKey;
}

/*
    Program Functions
*/

export const getProgram = ({
    connection,
    wallet,
}: GetProgramArgs): anchor.Program<DegenerateStar> => {
    const provider = new anchor.AnchorProvider(
        connection,
        wallet,
        anchor.AnchorProvider.defaultOptions()
    );
    return new anchor.Program(IDL, PROGRAM_ID, provider);
};

export const getProgramInactive = (): anchor.Program<DegenerateStar> => {
    const mockWallet = {
        publicKey: Keypair.generate().publicKey,
        signTransaction: () => Promise.reject(),
        signAllTransactions: () => Promise.reject(),
    };
    const provider = new anchor.AnchorProvider(
        connection,
        mockWallet,
        anchor.AnchorProvider.defaultOptions()
    );
    return new anchor.Program(IDL, PROGRAM_ID, provider);
};

export const getStarAddress = ({
    creator,
}: GetStarAddressArgs): [PublicKey, number] => {
    return PublicKey.findProgramAddressSync(
        [Buffer.from(STAR_SEED), creator.toBuffer()],
        PROGRAM_ID
    );
};

export const getMetadataAddress = ({
    token,
    star,
}: GetMetadataAddressArgs): [PublicKey, number] => {
    return PublicKey.findProgramAddressSync(
        [Buffer.from(METADATA_SEED), token.toBuffer(), star.toBuffer()],
        PROGRAM_ID
    );
};

export const getBookAddress = ({
    owner,
    star,
}: GetBookAddressArgs): [PublicKey, number] => {
    return PublicKey.findProgramAddressSync(
        [Buffer.from(BOOK_SEED), owner.toBuffer(), star.toBuffer()],
        PROGRAM_ID
    );
};

export const getVoucherAddress = ({
    token,
    star,
}: GetVoucherAddressArgs): [PublicKey, number] => {
    return PublicKey.findProgramAddressSync(
        [Buffer.from(VOUCHER_SEED), token.toBuffer(), star.toBuffer()],
        PROGRAM_ID
    );
};

export const getEnrollmentAddress = ({
    owner,
    star,
    token,
}: GetEnrollmentAddressArgs): [PublicKey, number] => {
    const book = getBookAddress({ owner, star })[0];
    return PublicKey.findProgramAddressSync(
        [
            Buffer.from(ENROLLMENT_SEED),
            owner.toBuffer(),
            book.toBuffer(),
            token.toBuffer(),
        ],
        PROGRAM_ID
    );
};

export const getTokenMetadataAddress = ({
    mint,
}: GetTokenMetadataAddressArgs): anchor.web3.PublicKey => {
    return anchor.web3.PublicKey.findProgramAddressSync(
        [
            Buffer.from("metadata"),
            METADATA_PROGRAM_ID.toBuffer(),
            mint.toBuffer(),
        ],
        METADATA_PROGRAM_ID
    )[0];
};

export const getEditionAddress = ({
    mint,
}: GetEditionAddressArgs): anchor.web3.PublicKey => {
    return anchor.web3.PublicKey.findProgramAddressSync(
        [
            Buffer.from("metadata"),
            METADATA_PROGRAM_ID.toBuffer(),
            mint.toBuffer(),
            Buffer.from("edition"),
        ],
        METADATA_PROGRAM_ID
    )[0];
};

export const getTokenRecordAddress = ({
    owner,
    token,
}: GetTokenRecordAddressArgs): anchor.web3.PublicKey => {
    const tokenAccount = getAssociatedTokenAddressSync(token, owner);
    return anchor.web3.PublicKey.findProgramAddressSync(
        [
            Buffer.from("metadata"),
            METADATA_PROGRAM_ID.toBuffer(),
            token.toBuffer(),
            Buffer.from("token_record"),
            tokenAccount.toBuffer(),
        ],
        METADATA_PROGRAM_ID
    )[0];
};

export const getMetadataAccounts = async ({
    connection,
    wallet,
    tokens,
}: GetMetadataAccountsArgs) => {
    const program = getProgram({ connection, wallet });
    const metadatas = tokens.map((token: string) => {
        return getMetadataAddress({
            token: new PublicKey(token),
            star: GOLD_STAR_ID,
        })[0];
    });
    const accounts = await program.account.metadata.fetchMultiple(metadatas);
    return accounts.filter((i) => i != null) as MetadataAccountInfo[];
};

export const getVoucherAccounts = async ({
    connection,
    wallet,
    tokens,
}: GetVoucherAccountsArgs) => {
    const program = getProgram({ connection, wallet });
    const vouchers = tokens.map((token: string) => {
        return getVoucherAddress({
            token: new PublicKey(token),
            star: GOLD_STAR_ID,
        })[0];
    });
    const accounts = await program.account.voucher.fetchMultiple(vouchers);
    return accounts.filter((i) => i != null) as VoucherAccountInfo[];
};

export const getEnrollmentAccounts = async ({
    connection,
    wallet,
    tokens,
}: GetEnrollmentAccountsArgs) => {
    const program = getProgram({ connection, wallet });
    const enrollmentRecords = tokens.map((token: string) => {
        return getEnrollmentAddress({
            owner: wallet.publicKey,
            token: new PublicKey(token),
            star: OPAL_ID,
        })[0];
    });
    const accounts =
        await program.account.enrollment.fetchMultiple(enrollmentRecords);
    return accounts.filter((i) => i != null) as EnrollmentAccountInfo[];
};

export const getBookAccount = async ({
    connection,
    wallet,
}: GetBookAccountArgs) => {
    const program = getProgram({ connection, wallet });
    const [stickerBook, _] = getBookAddress({
        owner: wallet.publicKey,
        star: GOLD_STAR_ID,
    });
    try {
        return await program.account.book.fetch(stickerBook);
    } catch (e) {
        return null;
    }
};

export const getPouchAccount = async ({
    connection,
    wallet,
}: GetBookAccountArgs) => {
    const program = getProgram({ connection, wallet });
    const [stickerBook, _] = getBookAddress({
        owner: wallet.publicKey,
        star: OPAL_ID,
    });
    try {
        return await program.account.book.fetch(stickerBook);
    } catch (e) {
        return null;
    }
};

export const getCreateBookInstruction = async ({
    connection,
    wallet,
}: CreateBookArgs) => {
    const program = getProgram({ connection, wallet });
    const [book, _] = getBookAddress({
        owner: wallet.publicKey,
        star: GOLD_STAR_ID,
    });
    return await program.methods
        .createBook()
        .accounts({
            owner: wallet.publicKey,
            star: GOLD_STAR_ID,
            book,
            systemProgram: SystemProgram.programId,
        })
        .instruction();
};

export const getCreatePouchInstruction = async ({
    connection,
    wallet,
}: CreateBookArgs) => {
    const program = getProgram({ connection, wallet });
    const [book, _] = getBookAddress({
        owner: wallet.publicKey,
        star: OPAL_ID,
    });
    return await program.methods
        .createBook()
        .accounts({
            owner: wallet.publicKey,
            star: OPAL_ID,
            book,
            systemProgram: SystemProgram.programId,
        })
        .instruction();
};

export const getClaimRewardInstruction = async ({
    connection,
    wallet,
    token,
    tokenAccount,
}: ClaimRewardArgs) => {
    const program = getProgram({ connection, wallet });
    const [book, _1] = getBookAddress({
        owner: wallet.publicKey,
        star: GOLD_STAR_ID,
    });
    const [metadata, _2] = getMetadataAddress({
        token,
        star: GOLD_STAR_ID,
    });
    return await program.methods
        .claimReward()
        .accounts({
            owner: wallet.publicKey,
            token,
            tokenAccount,
            star: GOLD_STAR_ID,
            metadata,
            book,
        })
        .instruction();
};

export const getBurnStarsInstruction = async ({
    connection,
    wallet,
    amount,
}: BurnStarsArgs) => {
    const program = getProgram({ connection, wallet });
    const [book, _] = getBookAddress({
        owner: wallet.publicKey,
        star: GOLD_STAR_ID,
    });
    return await program.methods
        .burn(new anchor.BN(amount))
        .accounts({
            owner: wallet.publicKey,
            book,
        })
        .instruction();
};

export const getRedeemVoucherInstruction = async ({
    connection,
    wallet,
    token,
    tokenAccount,
}: RedeemVoucherArgs) => {
    const program = getProgram({ connection, wallet });
    const book = getBookAddress({
        owner: wallet.publicKey,
        star: GOLD_STAR_ID,
    })[0];
    const voucher = getVoucherAddress({
        token: token,
        star: GOLD_STAR_ID,
    })[0];
    const tokenEdition = getEditionAddress({ mint: token });
    return await program.methods
        .redeemVoucher()
        .accounts({
            owner: wallet.publicKey,
            star: GOLD_STAR_ID,
            book,
            token,
            tokenAccount,
            tokenEdition,
            voucher,
            tokenMetadataProgram: METADATA_PROGRAM_ID,
            tokenProgram: TOKEN_PROGRAM_ID,
        })
        .instruction();
};

export const getEnrollTokenInstruction = async ({
    connection,
    wallet,
    token,
    tokenAccount,
}: EnrollTokenArgs) => {
    const program = getProgram({ connection, wallet });
    const book = getBookAddress({
        owner: wallet.publicKey,
        star: OPAL_ID,
    })[0];
    const enrollment = getEnrollmentAddress({
        owner: wallet.publicKey,
        star: OPAL_ID,
        token,
    })[0];
    const nftMetadata = getTokenMetadataAddress({ mint: token });
    const nftEdition = getEditionAddress({ mint: token });
    return await program.methods
        .enrollToken()
        .accounts({
            owner: wallet.publicKey,
            star: OPAL_ID,
            book,
            enrollment,
            nftMint: token,
            nftAccount: tokenAccount,
            nftMetadata,
            nftEdition,
            tokenMetadataProgram: METADATA_PROGRAM_ID,
            tokenProgram: TOKEN_PROGRAM_ID,
            clock: anchor.web3.SYSVAR_CLOCK_PUBKEY,
            systemProgram: anchor.web3.SystemProgram.programId,
        })
        .instruction();
};

export const getEnrollTokenV2Instruction = async ({
    connection,
    wallet,
    token,
    tokenAccount,
    tokenAuthRules,
}: EnrollTokenArgs) => {
    console.log(tokenAuthRules!.toBase58());
    const program = getProgram({ connection, wallet });
    const book = getBookAddress({
        owner: wallet.publicKey,
        star: OPAL_ID,
    })[0];
    const enrollment = getEnrollmentAddress({
        owner: wallet.publicKey,
        star: OPAL_ID,
        token,
    })[0];
    const nftMetadata = getTokenMetadataAddress({ mint: token });
    const nftEdition = getEditionAddress({ mint: token });
    const tokenRecord = getTokenRecordAddress({
        owner: wallet.publicKey,
        token,
    });
    return await program.methods
        .enrollTokenV2()
        .accounts({
            owner: wallet.publicKey,
            star: OPAL_ID,
            book,
            enrollment,
            nftMint: token,
            nftAccount: tokenAccount,
            nftMetadata,
            nftEdition,
            tokenRecord,
            tokenAuthRules,
            tokenAuthRulesProgram: TOKEN_AUTH_RULES_PROGRAM_ID,
            tokenMetadataProgram: METADATA_PROGRAM_ID,
            tokenProgram: TOKEN_PROGRAM_ID,
            sysvarInstructions: anchor.web3.SYSVAR_INSTRUCTIONS_PUBKEY,
            clock: anchor.web3.SYSVAR_CLOCK_PUBKEY,
            systemProgram: anchor.web3.SystemProgram.programId,
        })
        .instruction();
};

export const getClaimAttendanceRewardInstruction = async ({
    connection,
    wallet,
    token,
    tokenAccount,
}: ClaimAttendanceRewardArgs) => {
    const program = getProgram({ connection, wallet });
    const book = getBookAddress({
        owner: wallet.publicKey,
        star: OPAL_ID,
    })[0];
    const enrollment = getEnrollmentAddress({
        owner: wallet.publicKey,
        star: OPAL_ID,
        token,
    })[0];
    const nftMetadata = getTokenMetadataAddress({ mint: token });
    return await program.methods
        .claimAttendanceRewards()
        .accounts({
            owner: wallet.publicKey,
            star: OPAL_ID,
            book,
            enrollment,
            nftMint: token,
            nftAccount: tokenAccount,
            nftMetadata,
            clock: anchor.web3.SYSVAR_CLOCK_PUBKEY,
        })
        .instruction();
};

export const getUnenrollTokenInstruction = async ({
    connection,
    wallet,
    token,
    tokenAccount,
}: UnenrollTokenArgs) => {
    const program = getProgram({ connection, wallet });
    const book = getBookAddress({
        owner: wallet.publicKey,
        star: OPAL_ID,
    })[0];
    const enrollment = getEnrollmentAddress({
        owner: wallet.publicKey,
        star: OPAL_ID,
        token,
    })[0];
    const nftMetadata = getTokenMetadataAddress({ mint: token });
    const nftEdition = getEditionAddress({ mint: token });
    return await program.methods
        .unenrollToken()
        .accounts({
            owner: wallet.publicKey,
            star: OPAL_ID,
            book,
            enrollment,
            nftMint: token,
            nftAccount: tokenAccount,
            nftMetadata,
            nftEdition,
            tokenMetadataProgram: METADATA_PROGRAM_ID,
            tokenProgram: TOKEN_PROGRAM_ID,
            clock: anchor.web3.SYSVAR_CLOCK_PUBKEY,
            systemProgram: anchor.web3.SystemProgram.programId,
        })
        .instruction();
};

export const getUnenrollTokenV2Instruction = async ({
    connection,
    wallet,
    token,
    tokenAccount,
    tokenAuthRules,
}: UnenrollTokenArgs) => {
    const program = getProgram({ connection, wallet });
    const book = getBookAddress({
        owner: wallet.publicKey,
        star: OPAL_ID,
    })[0];
    const enrollment = getEnrollmentAddress({
        owner: wallet.publicKey,
        star: OPAL_ID,
        token,
    })[0];
    const nftMetadata = getTokenMetadataAddress({ mint: token });
    const nftEdition = getEditionAddress({ mint: token });
    const tokenRecord = getTokenRecordAddress({
        owner: wallet.publicKey,
        token,
    });
    return await program.methods
        .unenrollTokenV2()
        .accounts({
            owner: wallet.publicKey,
            star: OPAL_ID,
            book,
            enrollment,
            nftMint: token,
            nftAccount: tokenAccount,
            nftMetadata,
            nftEdition,
            tokenRecord,
            tokenAuthRules,
            tokenAuthRulesProgram: TOKEN_AUTH_RULES_PROGRAM_ID,
            tokenMetadataProgram: METADATA_PROGRAM_ID,
            tokenProgram: TOKEN_PROGRAM_ID,
            sysvarInstructions: anchor.web3.SYSVAR_INSTRUCTIONS_PUBKEY,
            clock: anchor.web3.SYSVAR_CLOCK_PUBKEY,
            systemProgram: anchor.web3.SystemProgram.programId,
        })
        .instruction();
};

/*
    IDL
*/

export type DegenerateStar = {
    version: "0.1.0";
    name: "degenerate_star";
    instructions: [
        {
            name: "createStar";
            accounts: [
                {
                    name: "authority";
                    isMut: true;
                    isSigner: true;
                },
                {
                    name: "creator";
                    isMut: true;
                    isSigner: true;
                },
                {
                    name: "star";
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: "systemProgram";
                    isMut: false;
                    isSigner: false;
                },
            ];
            args: [
                {
                    name: "bump";
                    type: "u8";
                },
                {
                    name: "kind";
                    type: "string";
                },
                {
                    name: "name";
                    type: "string";
                },
                {
                    name: "image";
                    type: "string";
                },
            ];
        },
        {
            name: "modifyStar";
            accounts: [
                {
                    name: "authority";
                    isMut: true;
                    isSigner: true;
                },
                {
                    name: "star";
                    isMut: true;
                    isSigner: false;
                },
            ];
            args: [
                {
                    name: "name";
                    type: "string";
                },
                {
                    name: "image";
                    type: "string";
                },
            ];
        },
        {
            name: "createMetadata";
            accounts: [
                {
                    name: "authority";
                    isMut: true;
                    isSigner: true;
                },
                {
                    name: "token";
                    isMut: false;
                    isSigner: false;
                },
                {
                    name: "star";
                    isMut: false;
                    isSigner: false;
                },
                {
                    name: "metadata";
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: "systemProgram";
                    isMut: false;
                    isSigner: false;
                },
            ];
            args: [
                {
                    name: "starsPerRedemption";
                    type: "u8";
                },
            ];
        },
        {
            name: "modifyMetadata";
            accounts: [
                {
                    name: "authority";
                    isMut: true;
                    isSigner: true;
                },
                {
                    name: "metadata";
                    isMut: true;
                    isSigner: false;
                },
            ];
            args: [
                {
                    name: "rewardsRemaining";
                    type: "u8";
                },
                {
                    name: "starsPerRedemption";
                    type: "u8";
                },
            ];
        },
        {
            name: "destroyMetadata";
            accounts: [
                {
                    name: "authority";
                    isMut: true;
                    isSigner: true;
                },
                {
                    name: "metadata";
                    isMut: true;
                    isSigner: false;
                },
            ];
            args: [];
        },
        {
            name: "createBook";
            accounts: [
                {
                    name: "owner";
                    isMut: true;
                    isSigner: true;
                },
                {
                    name: "star";
                    isMut: false;
                    isSigner: false;
                },
                {
                    name: "book";
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: "systemProgram";
                    isMut: false;
                    isSigner: false;
                },
            ];
            args: [];
        },
        {
            name: "incrementReward";
            accounts: [
                {
                    name: "authority";
                    isMut: true;
                    isSigner: true;
                },
                {
                    name: "metadata";
                    isMut: true;
                    isSigner: false;
                },
            ];
            args: [];
        },
        {
            name: "claimReward";
            accounts: [
                {
                    name: "owner";
                    isMut: true;
                    isSigner: true;
                },
                {
                    name: "token";
                    isMut: false;
                    isSigner: false;
                },
                {
                    name: "tokenAccount";
                    isMut: false;
                    isSigner: false;
                },
                {
                    name: "star";
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: "metadata";
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: "book";
                    isMut: true;
                    isSigner: false;
                },
            ];
            args: [];
        },
        {
            name: "transfer";
            accounts: [
                {
                    name: "authority";
                    isMut: true;
                    isSigner: true;
                },
                {
                    name: "star";
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: "sender";
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: "receiver";
                    isMut: true;
                    isSigner: false;
                },
            ];
            args: [
                {
                    name: "amount";
                    type: "u64";
                },
            ];
        },
        {
            name: "mint";
            accounts: [
                {
                    name: "authority";
                    isMut: true;
                    isSigner: true;
                },
                {
                    name: "star";
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: "book";
                    isMut: true;
                    isSigner: false;
                },
            ];
            args: [
                {
                    name: "amount";
                    type: "u64";
                },
            ];
        },
        {
            name: "burn";
            accounts: [
                {
                    name: "owner";
                    isMut: true;
                    isSigner: true;
                },
                {
                    name: "star";
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: "book";
                    isMut: true;
                    isSigner: false;
                },
            ];
            args: [
                {
                    name: "amount";
                    type: "u64";
                },
            ];
        },
        {
            name: "burnForced";
            accounts: [
                {
                    name: "authority";
                    isMut: true;
                    isSigner: true;
                },
                {
                    name: "star";
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: "book";
                    isMut: true;
                    isSigner: false;
                },
            ];
            args: [
                {
                    name: "amount";
                    type: "u64";
                },
            ];
        },
        {
            name: "createVoucher";
            accounts: [
                {
                    name: "authority";
                    isMut: true;
                    isSigner: true;
                },
                {
                    name: "star";
                    isMut: false;
                    isSigner: false;
                },
                {
                    name: "token";
                    isMut: false;
                    isSigner: false;
                },
                {
                    name: "voucher";
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: "systemProgram";
                    isMut: false;
                    isSigner: false;
                },
            ];
            args: [
                {
                    name: "amount";
                    type: "u64";
                },
            ];
        },
        {
            name: "redeemVoucher";
            accounts: [
                {
                    name: "owner";
                    isMut: true;
                    isSigner: true;
                },
                {
                    name: "star";
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: "book";
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: "token";
                    isMut: false;
                    isSigner: false;
                },
                {
                    name: "tokenAccount";
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: "tokenEdition";
                    isMut: false;
                    isSigner: false;
                },
                {
                    name: "voucher";
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: "tokenMetadataProgram";
                    isMut: false;
                    isSigner: false;
                },
                {
                    name: "tokenProgram";
                    isMut: false;
                    isSigner: false;
                },
            ];
            args: [];
        },
        {
            name: "destroyVoucher";
            accounts: [
                {
                    name: "authority";
                    isMut: true;
                    isSigner: true;
                },
                {
                    name: "star";
                    isMut: false;
                    isSigner: false;
                },
                {
                    name: "token";
                    isMut: false;
                    isSigner: false;
                },
                {
                    name: "voucher";
                    isMut: true;
                    isSigner: false;
                },
            ];
            args: [];
        },
        {
            name: "burnVoucher";
            accounts: [
                {
                    name: "owner";
                    isMut: true;
                    isSigner: true;
                },
                {
                    name: "star";
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: "token";
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: "tokenAccount";
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: "tokenEdition";
                    isMut: false;
                    isSigner: false;
                },
                {
                    name: "voucher";
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: "tokenMetadataProgram";
                    isMut: false;
                    isSigner: false;
                },
                {
                    name: "tokenProgram";
                    isMut: false;
                    isSigner: false;
                },
            ];
            args: [];
        },
        {
            name: "enrollToken";
            accounts: [
                {
                    name: "owner";
                    isMut: true;
                    isSigner: true;
                },
                {
                    name: "star";
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: "book";
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: "enrollment";
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: "nftMint";
                    isMut: false;
                    isSigner: false;
                },
                {
                    name: "nftAccount";
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: "nftMetadata";
                    isMut: false;
                    isSigner: false;
                },
                {
                    name: "nftEdition";
                    isMut: false;
                    isSigner: false;
                },
                {
                    name: "tokenMetadataProgram";
                    isMut: false;
                    isSigner: false;
                },
                {
                    name: "tokenProgram";
                    isMut: false;
                    isSigner: false;
                },
                {
                    name: "clock";
                    isMut: false;
                    isSigner: false;
                },
                {
                    name: "systemProgram";
                    isMut: false;
                    isSigner: false;
                },
            ];
            args: [];
        },
        {
            name: "enrollTokenV2";
            accounts: [
                {
                    name: "owner";
                    isMut: true;
                    isSigner: true;
                },
                {
                    name: "star";
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: "book";
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: "enrollment";
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: "nftMint";
                    isMut: false;
                    isSigner: false;
                },
                {
                    name: "nftAccount";
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: "nftMetadata";
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: "nftEdition";
                    isMut: false;
                    isSigner: false;
                },
                {
                    name: "tokenRecord";
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: "tokenAuthRules";
                    isMut: false;
                    isSigner: false;
                },
                {
                    name: "tokenAuthRulesProgram";
                    isMut: false;
                    isSigner: false;
                },
                {
                    name: "tokenMetadataProgram";
                    isMut: false;
                    isSigner: false;
                },
                {
                    name: "tokenProgram";
                    isMut: false;
                    isSigner: false;
                },
                {
                    name: "sysvarInstructions";
                    isMut: false;
                    isSigner: false;
                },
                {
                    name: "clock";
                    isMut: false;
                    isSigner: false;
                },
                {
                    name: "systemProgram";
                    isMut: false;
                    isSigner: false;
                },
            ];
            args: [];
        },
        {
            name: "unenrollToken";
            accounts: [
                {
                    name: "owner";
                    isMut: true;
                    isSigner: true;
                },
                {
                    name: "star";
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: "book";
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: "enrollment";
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: "nftMint";
                    isMut: false;
                    isSigner: false;
                },
                {
                    name: "nftAccount";
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: "nftMetadata";
                    isMut: false;
                    isSigner: false;
                },
                {
                    name: "nftEdition";
                    isMut: false;
                    isSigner: false;
                },
                {
                    name: "tokenMetadataProgram";
                    isMut: false;
                    isSigner: false;
                },
                {
                    name: "tokenProgram";
                    isMut: false;
                    isSigner: false;
                },
                {
                    name: "clock";
                    isMut: false;
                    isSigner: false;
                },
                {
                    name: "systemProgram";
                    isMut: false;
                    isSigner: false;
                },
            ];
            args: [];
        },
        {
            name: "unenrollTokenV2";
            accounts: [
                {
                    name: "owner";
                    isMut: true;
                    isSigner: true;
                },
                {
                    name: "star";
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: "book";
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: "enrollment";
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: "nftMint";
                    isMut: false;
                    isSigner: false;
                },
                {
                    name: "nftAccount";
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: "nftMetadata";
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: "nftEdition";
                    isMut: false;
                    isSigner: false;
                },
                {
                    name: "tokenRecord";
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: "tokenAuthRules";
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: "tokenAuthRulesProgram";
                    isMut: false;
                    isSigner: false;
                },
                {
                    name: "tokenMetadataProgram";
                    isMut: false;
                    isSigner: false;
                },
                {
                    name: "tokenProgram";
                    isMut: false;
                    isSigner: false;
                },
                {
                    name: "sysvarInstructions";
                    isMut: false;
                    isSigner: false;
                },
                {
                    name: "clock";
                    isMut: false;
                    isSigner: false;
                },
                {
                    name: "systemProgram";
                    isMut: false;
                    isSigner: false;
                },
            ];
            args: [];
        },
        {
            name: "claimAttendanceRewards";
            accounts: [
                {
                    name: "owner";
                    isMut: true;
                    isSigner: true;
                },
                {
                    name: "star";
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: "enrollment";
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: "book";
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: "nftMint";
                    isMut: false;
                    isSigner: false;
                },
                {
                    name: "nftAccount";
                    isMut: true;
                    isSigner: false;
                },
                {
                    name: "nftMetadata";
                    isMut: false;
                    isSigner: false;
                },
                {
                    name: "clock";
                    isMut: false;
                    isSigner: false;
                },
            ];
            args: [];
        },
    ];
    accounts: [
        {
            name: "star";
            type: {
                kind: "struct";
                fields: [
                    {
                        name: "authority";
                        type: "publicKey";
                    },
                    {
                        name: "creator";
                        type: "publicKey";
                    },
                    {
                        name: "bump";
                        type: "u8";
                    },
                    {
                        name: "supply";
                        type: "u128";
                    },
                    {
                        name: "kind";
                        type: "string";
                    },
                    {
                        name: "name";
                        type: "string";
                    },
                    {
                        name: "image";
                        type: "string";
                    },
                ];
            };
        },
        {
            name: "metadata";
            type: {
                kind: "struct";
                fields: [
                    {
                        name: "authority";
                        type: "publicKey";
                    },
                    {
                        name: "token";
                        type: "publicKey";
                    },
                    {
                        name: "star";
                        type: "publicKey";
                    },
                    {
                        name: "rewardsRemaining";
                        type: "u8";
                    },
                    {
                        name: "starsPerRedemption";
                        type: "u8";
                    },
                ];
            };
        },
        {
            name: "book";
            type: {
                kind: "struct";
                fields: [
                    {
                        name: "owner";
                        type: "publicKey";
                    },
                    {
                        name: "star";
                        type: "publicKey";
                    },
                    {
                        name: "amount";
                        type: "u64";
                    },
                ];
            };
        },
        {
            name: "voucher";
            type: {
                kind: "struct";
                fields: [
                    {
                        name: "token";
                        type: "publicKey";
                    },
                    {
                        name: "star";
                        type: "publicKey";
                    },
                    {
                        name: "redeemer";
                        type: "publicKey";
                    },
                    {
                        name: "amount";
                        type: "u64";
                    },
                    {
                        name: "used";
                        type: "bool";
                    },
                ];
            };
        },
        {
            name: "enrollment";
            type: {
                kind: "struct";
                fields: [
                    {
                        name: "owner";
                        type: "publicKey";
                    },
                    {
                        name: "mint";
                        type: "publicKey";
                    },
                    {
                        name: "star";
                        type: "publicKey";
                    },
                    {
                        name: "book";
                        type: "publicKey";
                    },
                    {
                        name: "collection";
                        type: "publicKey";
                    },
                    {
                        name: "dripTime";
                        type: "i64";
                    },
                ];
            };
        },
    ];
    errors: [
        {
            code: 6000;
            name: "InvalidProgram";
            msg: "The program ID is incorrect";
        },
        {
            code: 6001;
            name: "InvalidAuthority";
            msg: "This account does not have authority to perform this instruction";
        },
        {
            code: 6002;
            name: "NotEnoughStars";
            msg: "This sticker book doesn't have enough stars";
        },
        {
            code: 6003;
            name: "UnrecognizedTokenMint";
            msg: "This token mint is unrecognized";
        },
        {
            code: 6004;
            name: "NoRedemptionsAvailable";
            msg: "Account has nothing to redeem";
        },
        {
            code: 6005;
            name: "InvalidToken";
            msg: "You cannot attach this type of token";
        },
        {
            code: 6006;
            name: "TokenNotOwned";
            msg: "Requester does not own this token";
        },
        {
            code: 6007;
            name: "UnrecognizedStar";
            msg: "The Star account does not match this metadata";
        },
        {
            code: 6008;
            name: "KindTooLong";
            msg: "The kind string is too long";
        },
        {
            code: 6009;
            name: "NameTooLong";
            msg: "The name string is too long";
        },
        {
            code: 6010;
            name: "ImageTooLong";
            msg: "The image string is too long";
        },
        {
            code: 6011;
            name: "VoucherAlreadyUsed";
            msg: "This voucher has already been redeemed";
        },
        {
            code: 6012;
            name: "VoucherNotUsed";
            msg: "This voucher has not been redeemed yet";
        },
        {
            code: 6013;
            name: "InvalidCollection";
            msg: "What kind of token are you trying to enroll wtaf";
        },
        {
            code: 6014;
            name: "UnverifiedCollection";
            msg: "This collection is not verified";
        },
        {
            code: 6015;
            name: "InvalidMint";
            msg: "The token mint on this metadata account does not match";
        },
        {
            code: 6016;
            name: "InvalidConfiguration";
            msg: "The configuration does not match";
        },
        {
            code: 6017;
            name: "InvalidOwner";
            msg: "The owner does not own this enrollment record";
        },
    ];
};

export const IDL: DegenerateStar = {
    version: "0.1.0",
    name: "degenerate_star",
    instructions: [
        {
            name: "createStar",
            accounts: [
                {
                    name: "authority",
                    isMut: true,
                    isSigner: true,
                },
                {
                    name: "creator",
                    isMut: true,
                    isSigner: true,
                },
                {
                    name: "star",
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: "systemProgram",
                    isMut: false,
                    isSigner: false,
                },
            ],
            args: [
                {
                    name: "bump",
                    type: "u8",
                },
                {
                    name: "kind",
                    type: "string",
                },
                {
                    name: "name",
                    type: "string",
                },
                {
                    name: "image",
                    type: "string",
                },
            ],
        },
        {
            name: "modifyStar",
            accounts: [
                {
                    name: "authority",
                    isMut: true,
                    isSigner: true,
                },
                {
                    name: "star",
                    isMut: true,
                    isSigner: false,
                },
            ],
            args: [
                {
                    name: "name",
                    type: "string",
                },
                {
                    name: "image",
                    type: "string",
                },
            ],
        },
        {
            name: "createMetadata",
            accounts: [
                {
                    name: "authority",
                    isMut: true,
                    isSigner: true,
                },
                {
                    name: "token",
                    isMut: false,
                    isSigner: false,
                },
                {
                    name: "star",
                    isMut: false,
                    isSigner: false,
                },
                {
                    name: "metadata",
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: "systemProgram",
                    isMut: false,
                    isSigner: false,
                },
            ],
            args: [
                {
                    name: "starsPerRedemption",
                    type: "u8",
                },
            ],
        },
        {
            name: "modifyMetadata",
            accounts: [
                {
                    name: "authority",
                    isMut: true,
                    isSigner: true,
                },
                {
                    name: "metadata",
                    isMut: true,
                    isSigner: false,
                },
            ],
            args: [
                {
                    name: "rewardsRemaining",
                    type: "u8",
                },
                {
                    name: "starsPerRedemption",
                    type: "u8",
                },
            ],
        },
        {
            name: "destroyMetadata",
            accounts: [
                {
                    name: "authority",
                    isMut: true,
                    isSigner: true,
                },
                {
                    name: "metadata",
                    isMut: true,
                    isSigner: false,
                },
            ],
            args: [],
        },
        {
            name: "createBook",
            accounts: [
                {
                    name: "owner",
                    isMut: true,
                    isSigner: true,
                },
                {
                    name: "star",
                    isMut: false,
                    isSigner: false,
                },
                {
                    name: "book",
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: "systemProgram",
                    isMut: false,
                    isSigner: false,
                },
            ],
            args: [],
        },
        {
            name: "incrementReward",
            accounts: [
                {
                    name: "authority",
                    isMut: true,
                    isSigner: true,
                },
                {
                    name: "metadata",
                    isMut: true,
                    isSigner: false,
                },
            ],
            args: [],
        },
        {
            name: "claimReward",
            accounts: [
                {
                    name: "owner",
                    isMut: true,
                    isSigner: true,
                },
                {
                    name: "token",
                    isMut: false,
                    isSigner: false,
                },
                {
                    name: "tokenAccount",
                    isMut: false,
                    isSigner: false,
                },
                {
                    name: "star",
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: "metadata",
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: "book",
                    isMut: true,
                    isSigner: false,
                },
            ],
            args: [],
        },
        {
            name: "transfer",
            accounts: [
                {
                    name: "authority",
                    isMut: true,
                    isSigner: true,
                },
                {
                    name: "star",
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: "sender",
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: "receiver",
                    isMut: true,
                    isSigner: false,
                },
            ],
            args: [
                {
                    name: "amount",
                    type: "u64",
                },
            ],
        },
        {
            name: "mint",
            accounts: [
                {
                    name: "authority",
                    isMut: true,
                    isSigner: true,
                },
                {
                    name: "star",
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: "book",
                    isMut: true,
                    isSigner: false,
                },
            ],
            args: [
                {
                    name: "amount",
                    type: "u64",
                },
            ],
        },
        {
            name: "burn",
            accounts: [
                {
                    name: "owner",
                    isMut: true,
                    isSigner: true,
                },
                {
                    name: "star",
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: "book",
                    isMut: true,
                    isSigner: false,
                },
            ],
            args: [
                {
                    name: "amount",
                    type: "u64",
                },
            ],
        },
        {
            name: "burnForced",
            accounts: [
                {
                    name: "authority",
                    isMut: true,
                    isSigner: true,
                },
                {
                    name: "star",
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: "book",
                    isMut: true,
                    isSigner: false,
                },
            ],
            args: [
                {
                    name: "amount",
                    type: "u64",
                },
            ],
        },
        {
            name: "createVoucher",
            accounts: [
                {
                    name: "authority",
                    isMut: true,
                    isSigner: true,
                },
                {
                    name: "star",
                    isMut: false,
                    isSigner: false,
                },
                {
                    name: "token",
                    isMut: false,
                    isSigner: false,
                },
                {
                    name: "voucher",
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: "systemProgram",
                    isMut: false,
                    isSigner: false,
                },
            ],
            args: [
                {
                    name: "amount",
                    type: "u64",
                },
            ],
        },
        {
            name: "redeemVoucher",
            accounts: [
                {
                    name: "owner",
                    isMut: true,
                    isSigner: true,
                },
                {
                    name: "star",
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: "book",
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: "token",
                    isMut: false,
                    isSigner: false,
                },
                {
                    name: "tokenAccount",
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: "tokenEdition",
                    isMut: false,
                    isSigner: false,
                },
                {
                    name: "voucher",
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: "tokenMetadataProgram",
                    isMut: false,
                    isSigner: false,
                },
                {
                    name: "tokenProgram",
                    isMut: false,
                    isSigner: false,
                },
            ],
            args: [],
        },
        {
            name: "destroyVoucher",
            accounts: [
                {
                    name: "authority",
                    isMut: true,
                    isSigner: true,
                },
                {
                    name: "star",
                    isMut: false,
                    isSigner: false,
                },
                {
                    name: "token",
                    isMut: false,
                    isSigner: false,
                },
                {
                    name: "voucher",
                    isMut: true,
                    isSigner: false,
                },
            ],
            args: [],
        },
        {
            name: "burnVoucher",
            accounts: [
                {
                    name: "owner",
                    isMut: true,
                    isSigner: true,
                },
                {
                    name: "star",
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: "token",
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: "tokenAccount",
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: "tokenEdition",
                    isMut: false,
                    isSigner: false,
                },
                {
                    name: "voucher",
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: "tokenMetadataProgram",
                    isMut: false,
                    isSigner: false,
                },
                {
                    name: "tokenProgram",
                    isMut: false,
                    isSigner: false,
                },
            ],
            args: [],
        },
        {
            name: "enrollToken",
            accounts: [
                {
                    name: "owner",
                    isMut: true,
                    isSigner: true,
                },
                {
                    name: "star",
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: "book",
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: "enrollment",
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: "nftMint",
                    isMut: false,
                    isSigner: false,
                },
                {
                    name: "nftAccount",
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: "nftMetadata",
                    isMut: false,
                    isSigner: false,
                },
                {
                    name: "nftEdition",
                    isMut: false,
                    isSigner: false,
                },
                {
                    name: "tokenMetadataProgram",
                    isMut: false,
                    isSigner: false,
                },
                {
                    name: "tokenProgram",
                    isMut: false,
                    isSigner: false,
                },
                {
                    name: "clock",
                    isMut: false,
                    isSigner: false,
                },
                {
                    name: "systemProgram",
                    isMut: false,
                    isSigner: false,
                },
            ],
            args: [],
        },
        {
            name: "enrollTokenV2",
            accounts: [
                {
                    name: "owner",
                    isMut: true,
                    isSigner: true,
                },
                {
                    name: "star",
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: "book",
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: "enrollment",
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: "nftMint",
                    isMut: false,
                    isSigner: false,
                },
                {
                    name: "nftAccount",
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: "nftMetadata",
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: "nftEdition",
                    isMut: false,
                    isSigner: false,
                },
                {
                    name: "tokenRecord",
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: "tokenAuthRules",
                    isMut: false,
                    isSigner: false,
                },
                {
                    name: "tokenAuthRulesProgram",
                    isMut: false,
                    isSigner: false,
                },
                {
                    name: "tokenMetadataProgram",
                    isMut: false,
                    isSigner: false,
                },
                {
                    name: "tokenProgram",
                    isMut: false,
                    isSigner: false,
                },
                {
                    name: "sysvarInstructions",
                    isMut: false,
                    isSigner: false,
                },
                {
                    name: "clock",
                    isMut: false,
                    isSigner: false,
                },
                {
                    name: "systemProgram",
                    isMut: false,
                    isSigner: false,
                },
            ],
            args: [],
        },
        {
            name: "unenrollToken",
            accounts: [
                {
                    name: "owner",
                    isMut: true,
                    isSigner: true,
                },
                {
                    name: "star",
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: "book",
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: "enrollment",
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: "nftMint",
                    isMut: false,
                    isSigner: false,
                },
                {
                    name: "nftAccount",
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: "nftMetadata",
                    isMut: false,
                    isSigner: false,
                },
                {
                    name: "nftEdition",
                    isMut: false,
                    isSigner: false,
                },
                {
                    name: "tokenMetadataProgram",
                    isMut: false,
                    isSigner: false,
                },
                {
                    name: "tokenProgram",
                    isMut: false,
                    isSigner: false,
                },
                {
                    name: "clock",
                    isMut: false,
                    isSigner: false,
                },
                {
                    name: "systemProgram",
                    isMut: false,
                    isSigner: false,
                },
            ],
            args: [],
        },
        {
            name: "unenrollTokenV2",
            accounts: [
                {
                    name: "owner",
                    isMut: true,
                    isSigner: true,
                },
                {
                    name: "star",
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: "book",
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: "enrollment",
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: "nftMint",
                    isMut: false,
                    isSigner: false,
                },
                {
                    name: "nftAccount",
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: "nftMetadata",
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: "nftEdition",
                    isMut: false,
                    isSigner: false,
                },
                {
                    name: "tokenRecord",
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: "tokenAuthRules",
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: "tokenAuthRulesProgram",
                    isMut: false,
                    isSigner: false,
                },
                {
                    name: "tokenMetadataProgram",
                    isMut: false,
                    isSigner: false,
                },
                {
                    name: "tokenProgram",
                    isMut: false,
                    isSigner: false,
                },
                {
                    name: "sysvarInstructions",
                    isMut: false,
                    isSigner: false,
                },
                {
                    name: "clock",
                    isMut: false,
                    isSigner: false,
                },
                {
                    name: "systemProgram",
                    isMut: false,
                    isSigner: false,
                },
            ],
            args: [],
        },
        {
            name: "claimAttendanceRewards",
            accounts: [
                {
                    name: "owner",
                    isMut: true,
                    isSigner: true,
                },
                {
                    name: "star",
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: "enrollment",
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: "book",
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: "nftMint",
                    isMut: false,
                    isSigner: false,
                },
                {
                    name: "nftAccount",
                    isMut: true,
                    isSigner: false,
                },
                {
                    name: "nftMetadata",
                    isMut: false,
                    isSigner: false,
                },
                {
                    name: "clock",
                    isMut: false,
                    isSigner: false,
                },
            ],
            args: [],
        },
    ],
    accounts: [
        {
            name: "star",
            type: {
                kind: "struct",
                fields: [
                    {
                        name: "authority",
                        type: "publicKey",
                    },
                    {
                        name: "creator",
                        type: "publicKey",
                    },
                    {
                        name: "bump",
                        type: "u8",
                    },
                    {
                        name: "supply",
                        type: "u128",
                    },
                    {
                        name: "kind",
                        type: "string",
                    },
                    {
                        name: "name",
                        type: "string",
                    },
                    {
                        name: "image",
                        type: "string",
                    },
                ],
            },
        },
        {
            name: "metadata",
            type: {
                kind: "struct",
                fields: [
                    {
                        name: "authority",
                        type: "publicKey",
                    },
                    {
                        name: "token",
                        type: "publicKey",
                    },
                    {
                        name: "star",
                        type: "publicKey",
                    },
                    {
                        name: "rewardsRemaining",
                        type: "u8",
                    },
                    {
                        name: "starsPerRedemption",
                        type: "u8",
                    },
                ],
            },
        },
        {
            name: "book",
            type: {
                kind: "struct",
                fields: [
                    {
                        name: "owner",
                        type: "publicKey",
                    },
                    {
                        name: "star",
                        type: "publicKey",
                    },
                    {
                        name: "amount",
                        type: "u64",
                    },
                ],
            },
        },
        {
            name: "voucher",
            type: {
                kind: "struct",
                fields: [
                    {
                        name: "token",
                        type: "publicKey",
                    },
                    {
                        name: "star",
                        type: "publicKey",
                    },
                    {
                        name: "redeemer",
                        type: "publicKey",
                    },
                    {
                        name: "amount",
                        type: "u64",
                    },
                    {
                        name: "used",
                        type: "bool",
                    },
                ],
            },
        },
        {
            name: "enrollment",
            type: {
                kind: "struct",
                fields: [
                    {
                        name: "owner",
                        type: "publicKey",
                    },
                    {
                        name: "mint",
                        type: "publicKey",
                    },
                    {
                        name: "star",
                        type: "publicKey",
                    },
                    {
                        name: "book",
                        type: "publicKey",
                    },
                    {
                        name: "collection",
                        type: "publicKey",
                    },
                    {
                        name: "dripTime",
                        type: "i64",
                    },
                ],
            },
        },
    ],
    errors: [
        {
            code: 6000,
            name: "InvalidProgram",
            msg: "The program ID is incorrect",
        },
        {
            code: 6001,
            name: "InvalidAuthority",
            msg: "This account does not have authority to perform this instruction",
        },
        {
            code: 6002,
            name: "NotEnoughStars",
            msg: "This sticker book doesn't have enough stars",
        },
        {
            code: 6003,
            name: "UnrecognizedTokenMint",
            msg: "This token mint is unrecognized",
        },
        {
            code: 6004,
            name: "NoRedemptionsAvailable",
            msg: "Account has nothing to redeem",
        },
        {
            code: 6005,
            name: "InvalidToken",
            msg: "You cannot attach this type of token",
        },
        {
            code: 6006,
            name: "TokenNotOwned",
            msg: "Requester does not own this token",
        },
        {
            code: 6007,
            name: "UnrecognizedStar",
            msg: "The Star account does not match this metadata",
        },
        {
            code: 6008,
            name: "KindTooLong",
            msg: "The kind string is too long",
        },
        {
            code: 6009,
            name: "NameTooLong",
            msg: "The name string is too long",
        },
        {
            code: 6010,
            name: "ImageTooLong",
            msg: "The image string is too long",
        },
        {
            code: 6011,
            name: "VoucherAlreadyUsed",
            msg: "This voucher has already been redeemed",
        },
        {
            code: 6012,
            name: "VoucherNotUsed",
            msg: "This voucher has not been redeemed yet",
        },
        {
            code: 6013,
            name: "InvalidCollection",
            msg: "What kind of token are you trying to enroll wtaf",
        },
        {
            code: 6014,
            name: "UnverifiedCollection",
            msg: "This collection is not verified",
        },
        {
            code: 6015,
            name: "InvalidMint",
            msg: "The token mint on this metadata account does not match",
        },
        {
            code: 6016,
            name: "InvalidConfiguration",
            msg: "The configuration does not match",
        },
        {
            code: 6017,
            name: "InvalidOwner",
            msg: "The owner does not own this enrollment record",
        },
    ],
};
