import { useEffect, useState } from "react";

import { JellyTriangle } from "@uiball/loaders";
import { ContentFrame } from "../ContentStack";
import { Token } from "../../helpers/solana";
import SelectionGrid from "./SelectionGrid";
import { getNum, sleep } from "../../helpers/utils";
import Loading from "../../assets/loading.png";
import ActionButton from "../ActionButton";

function Selection({
    data,
    active,
    setActive,
    showApeLoader,
    contentFrame,
    setContentFrame,
}: {
    data: Token[];
    active: Token | null;
    setActive: React.Dispatch<React.SetStateAction<Token | null>>;
    showApeLoader: boolean;
    contentFrame: ContentFrame;
    setContentFrame: React.Dispatch<React.SetStateAction<ContentFrame>>;
}) {
    // To prevent the animation from just appear/disappearing, we have to make sure
    // the element exists before applying the opacity transition. So we set a
    // small timeout to allow the animation to appear before transitioning.
    const [hideLoader, setHideLoader] = useState<boolean>(true);
    const [hideContent, setHideContent] = useState<boolean>(true);
    const [shown, setShown] = useState(false);
    const confirm = () => {
        setShown(false);
        sleep(250).then(() => setContentFrame("Confirm"));
    };
    useEffect(() => {
        if (showApeLoader)
            sleep(100).then(() => {
                setHideLoader(false);
                setHideContent(true);
            });
        else if (!showApeLoader) {
            sleep(100).then(() => setHideContent(false));
            sleep(500).then(() => setHideLoader(true));
        }
    }, [showApeLoader]);
    useEffect(() => {
        if (contentFrame === "Select") sleep(10).then(() => setShown(true));
    }, [contentFrame]);
    return (
        <div className="flex justify-center items-center w-full h-full">
            <div
                className={`relative flex justify-center items-center w-[1000px] h-[620px] bg-[#20202099] rounded-3xl shadow-4xl z-[6] transition duration-[300ms] ${
                    shown && contentFrame === "Select"
                        ? "opacity-100"
                        : "opacity-0"
                }`}
            >
                {(showApeLoader || !hideLoader) && (
                    <div
                        className={`absolute transition duration-[500ms] ease-in-out ${
                            showApeLoader && !hideLoader
                                ? "opacity-100"
                                : "opacity-0"
                        }`}
                    >
                        <JellyTriangle color="#FFFFFF99" />
                    </div>
                )}
                {!showApeLoader && (
                    <div
                        className={`absolute w-full h-full flex justify-center items-center transition duration-[500ms] ease-in-out ${
                            !showApeLoader && !hideContent
                                ? "opacity-100"
                                : "opacity-0"
                        }`}
                    >
                        <div
                            onClick={() => {
                                setShown(false);
                                sleep(250).then(() => setContentFrame("Intro"));
                            }}
                            className="absolute top-[33px] left-14 font-montserrat text-xs text-[#ffffff99] cursor-pointer transition hover:text-white"
                        >
                            ⬅&nbsp;&nbsp;&nbsp;Go Back
                        </div>
                        <div className="absolute top-[35px] font-boop text-md">
                            Select Ape
                        </div>
                        <SelectionGrid
                            data={data}
                            loadingAsset={Loading}
                            active={active}
                            setActive={setActive}
                        />
                        <div className="absolute bottom-[45px]">
                            <ActionButton
                                active={!!active}
                                text={
                                    active
                                        ? `Assend #${getNum(active)}`
                                        : "Waiting..."
                                }
                                onClick={() => {
                                    if (active) confirm();
                                }}
                            />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default Selection;
