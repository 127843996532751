import { useEffect, useState } from "react";
import useInterval from "../hooks/useInterval";
import { getAscendedCount } from "../helpers/solana";

function AscendCounter() {
    const [num, setNum] = useState<number>(0);
    useEffect(() => {
        getAscendedCount().then((r) => setNum(r));
    }, []);
    useInterval(() => {
        getAscendedCount().then((r) => setNum(r));
    }, 10000);
    return (
        <div className="absolute font-montserrat font-extrabold w-full top-[40px] pr-[40px] left-0 h-[120px] z-[99] text-sm flex justify-end items-center text-[#ffffff] align-right pointer-events-none">
            Assended:&nbsp;<b>{num}</b>
        </div>
    );
}

export default AscendCounter;
