import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import { PublicKey } from "@solana/web3.js";

// The API
export const DEGENERATE_API_ENDPOINT: string = "https://api.degenape.academy/";

// Gold Star Program stuff
export const GOLD_STAR_ID: PublicKey = new PublicKey(
    "2Tw3imU5SJLGuATJoFENz72FNvWPYfUkXccSWdkC6nsK"
);
export const OPAL_ID: PublicKey = new PublicKey(
    "2SbDfHkqAMvNFDdYUqzMJYtEb917qM2VDZZNKUogU4xy"
);

// RPC URIs
export const METAPLEX_RPC: string = "https://api.metaplex.solana.com/";
export const RPC_MAINNET: string =
    "https://mainnet.helius-rpc.com/?api-key=ad20960b-6b42-4eeb-b98b-b601dcbd00b6";
export const RPC_DEVNET: string =
    "https://solana-devnet.g.alchemy.com/v2/zVws8VkFbIu8sTPIkb8_0EOPmDhGijzj";
export const BOOTS_API_KEY: string = "V1mMCgj6KCadQjZiLgLce4zBpz4gY3lnaOykM9aZ";
export const DAA_V2_ITEM_CLASS: string =
    "79p2ELH5nEYWFSthVWYBGCicRBZ8W5i5vULBpW7wxEXb";
export const HELIUS_MAINNET_API_KEY: string =
    "ad20960b-6b42-4eeb-b98b-b601dcbd00b6";

export const MEMO_PROGRAM_ID = new PublicKey(
    "MemoSq4gqABAXKb96qnH8TysNcWxMyWCqXgDLGmfcHr"
);

export const VERIFY_MESSAGE = "z7KNEFNw2It2MALojtkrKd1q36zEtFNX";

export const ENV = {
    NETWORK: WalletAdapterNetwork.Mainnet,
    STRING: "mainnet-beta",
    RPC: RPC_MAINNET,
    HELIUS: HELIUS_MAINNET_API_KEY,
};
