import { Token } from "./solana";

export const sliceIntoChunks = (arr: any[], chunkSize: number) => {
    const res = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
        const chunk = arr.slice(i, i + chunkSize);
        res.push(chunk);
    }
    return res;
};

export const goTo = (url: string) => {
    window.open(url, "_blank");
};

export const getNum = (token: Token) => {
    return Number(token.onChainMetadata.metadata.data.name.split(`#`)[1]);
};

export const sleep = (ms: number) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
};

export const eggData = {
    HbVuJn5uaaJ3PYPvsqDNgLD1AHH3azwLauJv5VULP84L: {
        number: "001",
        bear: {
            image: "https://bafybeiaamvsp3icagvpossfhbsfzipnvsvghzv4xiq2vu6cjtdbiolkhau.ipfs.nftstorage.link/",
            background: "Teal",
            fur: "Teal Opal",
            head: "Third Eye",
            mouth: "None",
            clothing: "Opal Baldric",
            eyes: "Closed",
        },
        sword: {
            image: "https://bafybeic72oawu5a3caegkuj727m2vohniy4x2i27wy4ckowb6fnmypahxe.ipfs.nftstorage.link/",
            type: "Shortsword",
        },
    },
    PptUhkDuJ1cFPhJFJ2mAUJCpnTPfAZCAkfUPQjSZkSR: {},
    AFXnH7JhrmcoNowAiG5k1qLsHAMffkFXTuETQFxwrTQK: {
        number: "003",
        bear: {
            image: "https://bafybeialk33pabebekzzp76fmduf7uct52jzmvsn3da6sqetwwpw6ibl5u.ipfs.nftstorage.link/",
            background: "Yellow",
            fur: "Yellow Opal",
            head: "Third Eye",
            mouth: "None",
            clothing: "Opal Baldric",
            eyes: "Closed",
        },
        sword: {
            image: "https://bafybeiagjksgkwi4zfvsyk262nust4jswvmxx7ovn56s5u4oxp32xgpffm.ipfs.nftstorage.link/",
            type: "Gladius",
        },
    },
    "9dzybuqc4bq4qLS8us7nRivrr7seWUdH6c29EvLGMRC9": {
        number: "004",
        bear: {
            image: "https://bafybeibadgr334nmtwpyt5x7rml3rzlxaxkyswgxxbgezky6yha7fds7la.ipfs.nftstorage.link/",
            background: "Green",
            fur: "Green Opal",
            head: "Third Eye",
            mouth: "None",
            clothing: "Opal Baldric",
            eyes: "Closed",
        },
        sword: {
            image: "https://bafybeiee54x2dwott7td7m4bednpnkkzz6tbt6bruu6wt2lv7e44ezo5sa.ipfs.nftstorage.link/",
            type: "Longsword",
        },
    },
    AVinTSLuWWGkAr3UH39Xj3dY3ouA1Jw56CU1cvMZySxg: {
        number: "005",
        bear: {
            image: "https://bafybeic5obj6xnkbkagacqqjrxufuwkts6fvd47emiy4bur3iceu4qbjpa.ipfs.nftstorage.link/",
            background: "Blue",
            fur: "Ice Serpent",
            head: "Sssteve",
            mouth: "None",
            clothing: "Ankh Shawl",
            eyes: "Serpent",
        },
        sword: {
            image: "https://bafybeiheksv46ijv6alihvwyd36ajzoboaf6lynoieltggi3w4u2fwuszu.ipfs.nftstorage.link/",
            type: "Khopesh",
        },
    },
    CVnrZripRjFPrDBLtSc8jLwYTxXtKA2iYHCj9dMetT4j: {
        number: "006",
        bear: {
            image: "https://bafybeiahggsfy32kex4x3omfkon7vzn4fbfpeo6qxwbyl5vpzgounef6aq.ipfs.nftstorage.link/",
            background: "Red",
            fur: "Red Opal",
            head: "Third Eye",
            mouth: "None",
            clothing: "Opal Baldric",
            eyes: "Closed",
        },
        sword: {
            image: "https://bafybeigpq25fbzfz6o23cbst24uxfi7cepcahcnboquzepr3iy5ijnc7cq.ipfs.nftstorage.link/",
            type: "Greatsword",
        },
    },
    DJfca1kC3QMNrkDwcAqV89eoV5nhk8FT3cAibG9WV3p7: {
        number: "007",
        bear: {
            image: "https://bafybeidaghxu6q4h3nsciwln6nhhd4pqgbtmh4fyejvmjscgddrofjjd7q.ipfs.nftstorage.link/",
            background: "Purple",
            fur: "Purple Opal",
            head: "Third Eye",
            mouth: "None",
            clothing: "Opal Baldric",
            eyes: "Closed",
        },
        sword: {
            image: "https://bafybeigybakqu7fr62agrgecbpmtt4bb4b34x6d2mpaqyiba6tyajjnwka.ipfs.nftstorage.link/",
            type: "Scimitar",
        },
    },
    HQpShr8VoZmK7oYvoWZWkaeS2uyG1uJoeRvcotA9KDn1: {
        number: "008",
        bear: {
            image: "https://bafybeihpbzg5uo3stfbck4xgwqlupsquyfwlg5mtvqoa7kp7iozjjedb7m.ipfs.nftstorage.link/",
            background: "Black",
            fur: "Black Opal",
            head: "Third Eye",
            mouth: "None",
            clothing: "Opal Baldric",
            eyes: "Closed",
        },
        sword: {
            image: "https://bafybeihzeu75k7abboie6nkedqrzfezt7kr2ax46k6goiigkjir2t7xvvm.ipfs.nftstorage.link/",
            type: "Broadsword",
        },
    },
    D3LVGPPMbwFArbmPnsARdiXyQXdvx21XBSLFvaMgHxeT: {
        number: "009",
        bear: {
            image: "https://bafybeiaamvsp3icagvpossfhbsfzipnvsvghzv4xiq2vu6cjtdbiolkhau.ipfs.nftstorage.link/",
            background: "Teal",
            fur: "Teal Opal",
            head: "Third Eye",
            mouth: "None",
            clothing: "Opal Baldric",
            eyes: "Closed",
        },
        sword: {
            image: "https://bafybeic72oawu5a3caegkuj727m2vohniy4x2i27wy4ckowb6fnmypahxe.ipfs.nftstorage.link/",
            type: "Shortsword",
        },
    },
};
